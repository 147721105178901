
import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import logo from "../assets/Takmon Boost full.svg";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // Mobile menu toggle
  const [dropdownState, setDropdownState] = useState({}); // Track which dropdown is open
  const currentPath = window.location.pathname;
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const [openNestedDropdown, setOpenNestedDropdown] = useState(null); // Tracks the open nested dropdown

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const navigate = useNavigate();
  const toggleNestedDropdown = (nestedName) => {
    setOpenNestedDropdown((prev) => (prev === nestedName ? null : nestedName));
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleNestedItemClick = () => {
    setDropdownState({}); // Close all dropdowns
    setIsOpen(false); // Close the mobile menu
  };

  const handleDropdownToggle = (dropdownKey) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdownKey]: !prevState[dropdownKey],
    }));
  };

  const serviceDropdownItems = [
    {
      name: "SEO Services",
      to: "/seo/services",
      nestedItems: [
        { name: "Local Business SEO", to: "/services/seo/local-business" },
        { name: "Outsource SEO", to: "/services/seo/outsource" },
        { name: "Small Business SEO", to: "/services/seo/small-business" },
        { name: "On-Page SEO", to: "/services/seo/onpage" },
        { name: "Off-Page SEO", to: "/services/seo/offpage" },
        { name: "Technical SEO", to: "/services/seo/technical" },
      ],
    },
    { name: "Social Media Management", to: "/services/social-media" },

    {
      name: "PPC Services", to: "/services/ppc",
      nestedItems: [
        { name: "Google Ads Management", to: "/services/seo/local-business" },
        { name: "Google Search Ads", to: "/services/seo/outsource" },
        { name: "Video Ads Campaign", to: "/services/seo/small-business" },


      ],
    },
    {
      name: "SMM Services",
      to: "/smm/services",
      nestedItems: [
        { name: "Facebook Marketing", to: "/services/smm/facebook" },
        { name: "Instagram Marketing", to: "/services/smm/instagram" },
        { name: "YouTube Marketing", to: "/services/smm/youtube" },
        { name: "Linkedin Marketing", to: "/services/smm/linkedin" },
        { name: "Twitter Marketing", to: "/services/smm/twitter" },
      ],
    },
    { name: "SMO Services", to: "/services/smo " },
    {
      name: "Web Design Services",
      to: "/services/web-design",
      nestedItems: [
        { name: "WordPress", to: "/services/web-design/wordpress" },
        { name: "Ecommerce", to: "/services/web-design/ecommerce" },
        { name: "Custom Website", to: "/services/web-design/custom" },
        { name: "Website Maintenance", to: "/services/web-design/maintenance" },
      ],
    },
  ];

  const Navigate = useNavigate();

  const menuItems = [
    { name: "Home", to: "/" },
    { name: "Services", to: "/services" },
    { name: "Packages", to: "/package" },
    { name: "Our Plan", to: "/ourPlan" },
    { name: "Blogs", to: "/blogs" },
    { name: "About Us", to: "/about" },
    { name: "Contact Us", to: "/contact" },
  ];

  return (
    <div className="bg-white mt-0 fixed w-full z-30">
      <nav className=" w-full z-30 top-0 start-0 scroll-smooth fixed mt-0 bg-white">

        <div className="flex items-center justify-around h-16">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src={logo}
              alt="Logo"
              className="w-36 h-20 object-contain cursor-pointer"
              onClick={() => Navigate('/')}
            />
          </div>
          <div className="hidden lg:flex items-center space-x-10 relative">
            <div className="ml-10 flex items-baseline space-x-3">
              {menuItems.map((item) =>
                item.name === "Services" ? (
                  <div
                    key={item.name}
                    className="relative"
                    onMouseEnter={() => handleDropdownToggle("services")}
                    onMouseLeave={() => handleDropdownToggle("services")}
                  >
                    <Link
                      to="#"
                      onClick={() => setDropdownState({})} // Close dropdown on click
                      className={`${currentPath.includes("/services")
                          ? "text-white bg-custom-orenge font-bold"
                          : "text-black font-semibold hover:text-white hover:bg-custom-orenge"
                        } flex items-center px-3 py-2 rounded-md text-base`}
                    >
                      {item.name}
                      <svg
                        className={`w-4 h-4 ml-2 transition-transform ${dropdownState.services ? "rotate-180" : ""
                          }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </Link>
                    {dropdownState.services && (
                      <div className="absolute z-10 left-0 mt-0 bg-white rounded-md shadow-lg w-40">
                        {serviceDropdownItems.map((dropdownItem) => (
                          <div
                            key={dropdownItem.name}
                            className="relative"
                            onMouseEnter={() => toggleDropdown(dropdownItem.name)}
                            onMouseLeave={() => toggleDropdown(null)}
                          >
                            <Link
                              to={dropdownItem.to}
                              onClick={() => setDropdownState({})} // Close dropdown on click
                              className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                            >
                              {dropdownItem.name}
                              {dropdownItem.nestedItems && (
                                <svg
                                  className={`w-4 h-4 ml-2 transition-transform ${openDropdown === dropdownItem.name ? "rotate-180" : ""
                                    }`}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              )}
                            </Link>
                            {openDropdown === dropdownItem.name &&
                              dropdownItem.nestedItems && (
                                <div className="absolute left-full top-0 mt-0 bg-white rounded-md shadow-lg w-52">
                                  {dropdownItem.nestedItems.map((seoItem) => (
                                    <Link
                                      key={seoItem.name}
                                      to={seoItem.to}
                                      onClick={() => setDropdownState({})} // Close dropdown on click
                                      className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                                    >
                                      {seoItem.name}
                                    </Link>
                                  ))}
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    key={item.name}
                    to={item.to}
                    onClick={() => setDropdownState({})} // Close dropdown on click
                    className={`${currentPath === item.to
                        ? "text-white bg-custom-orenge font-bold"
                        : "text-black font-semibold hover:text-white hover:bg-custom-orenge"
                      } px-3 py-2 rounded-md text-base`}
                  >
                    {item.name}
                  </Link>
                )
              )}
            </div>
          </div>
          {/* Mobile Menu Button */}
          <div className=" flex lg:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-custom-orenge"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>


        {/* Mobile Menu */}
        {isOpen && (
          <div className="lg:hidden px-2 pt-2 pb-3 space-y-1 w-60 absolute left-0 top-full  bg-gray-50 border border-gray-200 rounded-md shadow-lg"style={{ maxHeight: "500px", overflowY: "auto" }}>
            {menuItems.map((item) =>
              item.name === "Services" ? (
                <div key={item.name} className="relative">
                  <button
                    onClick={() => handleDropdownToggle("services-mobile")}
                    className="flex items-center justify-start w-full text-black hover:text-custom-orenge px-3 py-2 rounded-md text-base font-medium"
                  >
                    {item.name}
                    <svg
                      className={`w-4 h-4 ml-2 transition-transform ${dropdownState["services-mobile"] ? "rotate-180" : ""
                        }`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {dropdownState["services-mobile"] && (
                    <div className="relative bg-gray-50 border border-gray-200 rounded-md shadow-lg w-60 top-full"  style={{ maxHeight: "400px", overflowY: "auto" }}>
                      {serviceDropdownItems.map((dropdownItem) => (
                        <div key={dropdownItem.name} className="relative group">
                          <button
                            onClick={() => toggleDropdown(dropdownItem.name)}
                            className="flex items-center justify-around w-full text-black hover:text-custom-orenge px-3 py-2 rounded-md text-base font-medium"
                          >
                            <Link
                              to={dropdownItem.to}
                              onClick={() => {
                                setIsOpen(false); // Close menu after navigation
                                setDropdownState({});
                              }} // Close dropdown on click
                              className="block px-4 py-2 text-xm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                            ></Link>
                            {dropdownItem.name}
                            {dropdownItem.nestedItems && (
                              <svg
                                className={`w-4 h-4 ml-2 transition-transform ${openDropdown === dropdownItem.name ? "rotate-180" : ""
                                  }`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            )}
                          </button>
                          {openDropdown === dropdownItem.name &&
                            dropdownItem.nestedItems && (
                              <div className="relative bg-gray-50 border border-gray-200 rounded-md shadow-lg w-60 top-full">
                                {dropdownItem.nestedItems.map((nestedItem) => (
                                  <Link
                                    key={nestedItem.name}
                                    to={nestedItem.to}
                                    onClick={() => {
                               setIsOpen(true); // Close menu
                                      setDropdownState({});
                                    }} // Close dropdown on click
                                    className="block px-4 py-2 text-sm text-black hover:bg-gray-100 hover:text-orange-600 rounded-md"
                                  >
                                    {nestedItem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={item.name}
                  to={item.to}
                  onClick={() => setIsOpen(false)} // Close menu on link click
                  className="text-black hover:text-custom-orenge block px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.name}
                </Link>
              )
            )}
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
