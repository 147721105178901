import React from 'react'

const SeoCard = ({ title, description,description2, bgColor = 'bg-white' }) => {
  return (
    <>
      <div className={`p-4 rounded-lg shadow hover:bg-orange-500 group ${bgColor}`}>
      <h3 className="font-bold text-lg mb-2 group-hover:text-white">{title}</h3>
      <p className="text-gray-700 text-sm group-hover:text-white">{description}</p>
      <p className="text-gray-700 text-sm group-hover:text-white">{description2}</p>
    </div> 

    </>
  )
}

export default SeoCard
