import React from "react";
import { LuUsers2 } from "react-icons/lu";
import { BsStar } from "react-icons/bs";
import { LiaRedoAltSolid } from "react-icons/lia";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer"; // Import useInView
import statusImage from "../../../assets/statusimage.jpeg";

const statsData = [
  {
    icon: <LuUsers2 className="text-4xl" />, // Icon for Happy Clients
    value: 10000, // Number for animation
    label: "Happy Clients",
    suffix: "+",
  },
  {
    icon: <LiaRedoAltSolid className="text-4xl" />, // Icon for Projects Done
    value: 200, // Number for animation
    label: "Projects Done",
    suffix: "+",
  },
  {
    icon: <BsStar className="text-4xl" />, // Icon for Years of Experience
    value: 4, // Number for animation
    label: "Years of Experience",
    suffix: " Years",
  },
];

const Coustumers = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <div
      className="bg-cover bg-center bg-no-repeat w-full h-auto flex items-center justify-center transition-transform sm:rotate-90 md:rotate-0"
      style={{
        backgroundImage: `url(${statusImage})`,
      }}
    >
      <div className="text-center text-white p-8">
        <h2 className="text-4xl font-bold mb-8">Our Customers</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[9rem]">
          {statsData.map((stat, index) => (
            <div
              key={index}
              className="flex flex-col items-center"
              ref={ref} // Attach the ref here to track visibility
            >
              {/* Outer Circle with Flex Space Between */}
              <div className="mb-4 relative w-28 h-28 md:w-24 md:h-24">
                {/* Outer Circle */}
                <div className="bg-white w-full h-full rounded-full flex items-center justify-between px-4 py-4">
                  {/* Empty Divs for Space and Centering */}
                  <div></div>
                  {/* Centered Icon */}
                  <div className="text-black">{stat.icon}</div>
                  <div></div>
                </div>
              </div>
              {/* Text Below Circle */}
              <p className="text-2xl md:text-3xl font-semibold">
                {inView && (
                  <CountUp
                    start={0}
                    end={stat.value}
                    duration={2.5}
                    suffix={stat.suffix}
                  />
                )}
              </p>
              <p className="mt-2">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coustumers;
