import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const OnPageSeo = () => {
    const options = [
        {
          title: 'Title Tags',
          description:
            'Title tags are among the most critical elements of On-Page SEO. It tells search engines what your page is about. The title tag should, therefore, be well crafted to incorporate a focus keyword. It should be persuasive and descriptive simultaneously. Ultimately, the title tag should not exceed 50 to 60 characters, or else it may get truncated in the results given by the search engine. As one of the best SEO agencies in Noida, we optimize your title tags for better ranking and click-through rates.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Meta Descriptions',
          description:'The meta description is an abridged version of the content of your page and will appear in the SERPs. Although it wsa not have a direct relation to rankings, an accurate meta description will play an indirect role in determining whether your link will be clicked upon. At Takmon Boost, we ensure that your meta descriptions are not only informative but also keyword-rich, and this increases the chance for higher engagement from the target users.',
          description2 :''
        },
        {
            title: 'URL Structure',
            description:'Clean and SEO-friendly URLs are pretty important for On-page SEO. A clean and readable URL should have a target keyword. Avert long complicated URLs that have extra characters. The URLs we design at Takmon Boost, Best SEO Agency in Noida are easy, short and search engine optimized, so the page content will become easy to understand both for users and for the search engines too.',
            description2 :''
          },
          {
            title: 'Header Tags (H1, H2, H3)',
            description:'Header tags give structure to your text; therefore, it improves its readability for users and search engines as well. Typically, the H1 heading would be the title for your page, with your subheadings typically being H2 or H3, that is even further structuring into content.',
            description2 :''
          },
          {
            title: ' Why Choose Takmon Boost, the Best SEO Agency in Noida?',
            description:'At Takmon Boost, we understand the intricacies involved with On-Page SEO and its effects on all-around website performance. From being the best SEO agency Noida has to offer, we know how to use the new tools and strategies to optimize every aspect of your site.',
            description2 :''
          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="On-Page SEO "
       subHeading="On-page SEO: Improve Performance of Your Website with Takmon Boost-The Best SEO Agency in Noida."
       description="
As digital marketing is one of the increasingly competitive fields of the day, mastering SEO for achieving success online. One such significant aspect of SEO has been On-page SEO or The Best SEO Agency in Noida process of optimizing your website to enhance its contents and structuring for achieving better positions in search engine rankings."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="What is On-Page SEO?"
      description="On-Page SEO is the process of optimizing individual web pages to rank higher and earn more relevant traffic in search engines. It is not off-page SEO, which deals with activities outside your website. On-Page SEO refers to optimizing content, HTML tags, images, and other elements that make your site more search engine-friendly. In this way, search engines can easily crawl and understand the content of your website.."
      title2="Key Components of On-Page SEO"
      description2="Content Optimization-The very basis of On-Page SEO is the presence of good, relevant, and engaging content. Since search engines are looking for the most satisfactory answer to a user's query, content that is drafted for hitting target keywords performs well with the search engines. The best SEO agency in Noida is Takmon Boost, which works on optimized content that will not just inform the audience but also speak about the intent of searches, thus ensuring it performs well on Google."
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >On-Page SEO is the most crucial part of any successful digital marketing strategy. You can optimize your content, title tags, meta descriptions, URLs, header tags, and images, and you might get excellent search engine rankings for your website. With Takmon Boost, the best SEO agency in Noida, you can ensure that your website reaches its full potential and never lags behind others.




</p></div>
      
  
     
  </>
  )
}

export default OnPageSeo
