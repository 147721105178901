import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'

const SmallBusinessseo = () => {
    const options = [
        {
          title: 'Why Takmon Boost is a Professional SEO Company in India',
          description:
            ' Takmon Boost is an India-based professional SEO company that has managed to gain repute by serving small businesses with quality services. The team here is up to date about the new SEO trends and Google updates so your website is not left behind amidst all the changes happening in this digital world.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Local SEO Expertise',
          description:'Local SEO is actually the most essential part of SEO to small businesses. Takmon Boost understands ways of executing local SEO strategy in the Professional SEO Company in India way that business can notice them in its specific geography. It might be any shop, or service provider, or might be some e-commerce-based business. They will opt for optimization of your website to rank your site in local search results, which leads to having customers who might be nearby and ready to connect.',
          description2 :''
        },
        {
            title: 'Practical',
            description:'Setting up an in-house Search engine optimization group requires a ton of interest in employing, preparing, and keeping up with the staff. You also have to invest in different SEO tools and technologies. The best SEO company in Jaipur like Takmon Boost can present a cost-effective alternative solution. Outsourcing can get you the best SEO services for only a fraction of the overall cost, maximizing value from an investmen',
            description2 :''
          },
          {
            title: 'Customized SEO Strategies',
            description:'Each business is distinct, and thus are their SEO requirements. Best SEO service in Jaipur, Takmon Boost realizes this very fact and therefore devises its SEO strategies with customization aligned with your specific business requirements. Be it small local enterprises or big firms; they construct the strategies specifically suited for you, for guaranteed outcomes.',
            description2 :''
          },
          {
            title: ' Latest Available SEO Tools End',
            description:'SEO encompasses a wide range of tools as far as keyword analysis, website performance is concerned, and assessment of the strategy. Takmon Boost possesses advanced SEO tools for analyzing deeply to refine the strategy and provide more effective results. A very expensive cost to onboard an in-house team will invest in such tools; outsourcing to the best SEO company in Jaipur assures cutting-edge technology at reasonable prices..',
            description2 :''
          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Small Business SEO  "
       subHeading="Small Business SEO: Why You Need a Professional SEO Company in India like Takmon Boost"
       description="Present times is a digital world, wherein small businesses find themselves being challenged by various competitions; staying ahead of competition often falls under the heads of some key tools which are efficiently creating your professional seo company in india  presence over online media. It is no brainer that one such tool remains to be SEO."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why Small Businesses Need SEO"
      description="SEO is an essential type of digital marketing. With such better rankings, it gets your website ranked in search engines like Google, and you bring more organic traffic to the website, and this gives way to higher sales and growth of the business. SEO is particularly beneficial to small businesses since they have a much fairer chance of competing with larger opponents."
      title2="However, that is still far from the truth because SEO cannot be an easy exercise for just putting keywords in and about the website. This rather needs a deep sense of understanding algorithms from the search engines, research in keywords, content generation, and technical optimization. One can do all this in a professional SEO company, such as Takmon Boost from India"
      description2="Benefits of Hiring the Professional SEO Company in India: Takmon Boost.
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >One of the Working with an expert Website design enhancement organization in India, for example, Takmon Lift, would be a confirmation that your business' Web optimization activity is overseen by specialists in the information on the Professional SEO Company in India Unique hunt calculations and results-situated techniques. Complete Website design enhancement administrations given by Takmon Lift, financially savvy arrangements, and neighborhood Web optimization aptitude guarantee development and accomplishment for private ventures in computerized space.


</p></div>
      
  
     
  </>
  )
}

export default SmallBusinessseo
