import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const WordPress = () => {
    const options = [
        {
          title: 'Why is Takmon Boost for WordPress Website Development?',
          description:
            "Being a prominent WordPress website development company in Jodhpur, Takmon Boost stands different from others due to our commitment to excellence. Here's what makes us the trusted choice:",
              description2 :'Custom Solutions: Every business comes with different needs. Custom WordPress sites are made for you based on your branding and business goals by us.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Expert Developers and Designers:',
          description:' Our team contains experienced developers and designers proficient in WordPress development for over years now.',
          description2 :'Delivery in Given Time Frame:We consider strict timelines so that our website gets launched according to the deadline given to our clients.'
        },
        {
            title: ' Economically Viable Services:',
            description:" Quality need not go hand-in-hand with expenditure. Economical WordPress solutions.",
            description2 :'Ongoing Support: From development to maintenance, we provide end-to-end support to keep your website running seamlessly.'
          },
          {
            title: 'Customized SEO Strategies',
            description:'Each business is distinct, and thus are their SEO requirements. Best SEO service in Jaipur, Takmon Boost realizes this very fact and therefore devises its SEO strategies with customization aligned with your specific business requirements. Be it small local enterprises or big firms; they construct the strategies specifically suited for you, for guaranteed outcomes.',
            description2 :''
          },
          {
            title: ' Latest Available SEO Tools End',
            description:'SEO encompasses a wide range of tools as far as keyword analysis, website performance is concerned, and assessment of the strategy. Takmon Boost possesses advanced SEO tools for analyzing deeply to refine the strategy and provide more effective results. A very expensive cost to onboard an in-house team will invest in such tools; outsourcing to the best SEO company in Jaipur assures cutting-edge technology at reasonable prices..',
            description2 :''
          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="WordPress"
       subHeading="Takmon Boost: Leading WordPress Development Company in Jodhpur for Custom, Scalable, and SEO-Friendly Websites"
       description="Having the best online presence is indispensable in today's competitive digital landscape. At Takmon Boost, it's a leading WordPress website development company in Jodhpur, we believe that we can help them to achieve their digital aims in our endeavor and mission. We are expert service providers about making custom WordPress websites exactly to meet the unique requirement and business objectives of yours."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why WordPress is Best Suited Website"
      description="
WordPress has become the world's leading content management system, hosting over 40% of the web. It is easy to use, highly versatile, and has strong features, making it a go-to choice for any business size. At Takmon Boost, we make full use of WordPress. A website development company in Jodhpur  produces websites that are not only beautiful but also very functional. Here are some reasons why WordPress stands out:

Easy interface for management and update of any website without requiring technical background.
"
      title2=""
      description2="Innumerable design options are possible through WordPress, thanks to thousands of themes and plugins.

As WordPress is search engine-optimized, it makes it easier for your website to feature higher on search results.

Also, it is responsive mobile-friendly that means it allows your website to look better on any device from your desktop to smartphones.

Security and Reliability: Regular updates and wide security plugins make WordPress a safe choice
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'></h1>
      <p className="text-gray-700 text-sm" >


</p></div>
      
  
     
  </>
  )
}

export default WordPress
