import React, { useTransition, useState } from "react";
import ContactFormImage from "../LandingPage/images/ContactFormImage.png";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isPending, startTransition] = useTransition(); // React 19's useTransition
  const [successMessage, setSuccessMessage] = useState("");

  const onSubmit = (data) => {
    startTransition(() => {
      console.log(data);

      // Simulating API request delay with a timeout
      setTimeout(() => {
        // Show success message
        toast.success("Form submitted successfully!", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Reset form fields
        reset();

        // Set a success message
        setSuccessMessage("Thank you for getting in touch!");
      }, 2000); // Simulate a 2-second delay
    });
  };

  return (
    <>
      <span className="flex justify-center items-center font-bold text-2xl m-3 text-orange-500 ">
        \ Get In Touch \
      </span>
      <span className="flex justify-center items-center font-bold text-4xl m-3 text-black ">
        Hey! Let's Talk
      </span>
      <div className="flex flex-col md:flex-row justify-between items-center bg-white py-10 px-6 md:px-16">
        {/* Left Section: Form */}
        <div className="w-full md:w-1/2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              placeholder="Name"
              className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
              {...register("name", { required: "Name is required" })}
              disabled={isPending} // Disable input during transition
            />
            {errors.name && (
              <p className="text-red-500 text-sm">{errors.name.message}</p>
            )}

            <input
              type="email"
              placeholder="Email"
              className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                  message: "Please enter a valid email address",
                },
              })}
              disabled={isPending} // Disable input during transition
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email.message}</p>
            )}

            <input
              type="tel"
              placeholder="Phone"
              className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Please enter a valid 10-digit phone number",
                },
              })}
              disabled={isPending} // Disable input during transition
            />
            {errors.phone && (
              <p className="text-red-500 text-sm">{errors.phone.message}</p>
            )}

            <textarea
              placeholder="Your Message"
              className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 resize-none rounded-lg"
              rows="4"
              {...register("message", { required: "Message is required" })}
              disabled={isPending} // Disable textarea during transition
            />
            {errors.message && (
              <p className="text-red-500 text-sm">{errors.message.message}</p>
            )}

            <button
              type="submit"
              className={`w-full md:w-32 ${
                isPending
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-custom-orengelight hover:bg-orange-600"
              } text-white font-bold py-4 rounded-lg transition duration-300`}
              disabled={isPending} // Disable button during transition
            >
              {isPending ? "Sending..." : "Send Now"}
            </button>
          </form>
          {/* {successMessage && (
            <p className="text-green-500 text-center mt-4">{successMessage}</p>
          )} */}
          <ToastContainer />
        </div>

        {/* Right Section: Illustration */}
        <div className="w-50vw md:w-1/2 mt-10 md:mt-0 hidden md:flex justify-center items-center p-12">
          <img
            src={ContactFormImage} // Replace with your actual illustration/image URL
            alt="Illustration"
            className="w-2/3 md:w-2/3"
          />
        </div>
      </div>
    </>
  );
};

export default ContactForm;



