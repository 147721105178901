import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";
import bannerimg1 from "../../../assets/BannerImages/bannerimg1.jpeg";
import bannerimg3 from "../../../assets/BannerImages/bannerimg3.jpeg";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";

const Banner = () => {
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [hideBackground, setHideBackground] = useState(false);
    const [isSpecificResolution, setIsSpecificResolution] = useState(false);

    const navigate = useNavigate();

    // Handle screen resizing
    useEffect(() => {
        const handleResize = () => {
            const { innerWidth, innerHeight } = window;
            setIsLargeScreen(innerWidth >= 1024);
            setHideBackground(
                innerWidth < 1024 || (innerWidth === 1024 && innerHeight === 1366)
            );
            setIsSpecificResolution(innerWidth === 1024 && innerHeight === 1366);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleButtonClick = () => {
        console.log("Explore More button clicked!");
        navigate("/offer");
    };

    const slides = [
        {
            title: "Marketing Consulting’s",
            description: "Driving Growth with Marketing Insights",
            text: "Unlock business potential by leveraging marketing insights to drive strategic growth, enhance customer engagement, and optimize decision-making for success.",
            image: bannerimg3,
            tags: ["Development", "Branding", "Marketing", "SEO"],
        },
        {
            title: "Business Growth",
            description: "Strategies for Extraordinary Business Growth",
            text: "Unlock extraordinary business growth with innovative strategies: focus on market trends, customer insights, digital transformation, strategic partnerships, and continuous improvement to achieve sustainable success.",
            image: bannerimg1,
            tags: ["Strategy", "Innovation", "Growth", "Planning"],
        },
    ];

    return (
        <section className=" m-0 p-0 pt-10">
          
                <Swiper
                    modules={[Autoplay, Pagination]}
                    autoplay={{ delay: 4000 }}
                    loop={true}
                    slidesPerView={1}
                    className="w-full"
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="custom-slide text-white"
                                style={{
                                    backgroundImage: !hideBackground
                                        ? `url(${slide.image})`
                                        : "none",
                                }}
                            >
                                <div className="grid grid-cols-12 gap-4 items-center w-full px-6">
                                    {/* Left Side Content */}
                                    <div
                                        className={`col-span-12 ${isSpecificResolution ? "text-center" : "lg:col-span-7 lg:text-left"
                                            } p-6 rounded-lg lg:bg-transparent m-auto max-w-xl w-full`}
                                    >
                                        <h1
                                            className="font-bold animate-fadeInLeft mt-16 md:mt-auto leading-[1.2] lg:text-3xl md:text-[30px] sm:text-2xl text-[40px]"
                                            style={{
                                                background:
                                                    "linear-gradient(to right, #404B52, #F97316, #DC2626)",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                            }}
                                        >
                                            {slide.title}
                                        </h1>
                                        <div className=" relative w-80 h-[4px] bg-gradient-to-r from-orange-400 to-orange-600 mt-3 mb-5 mx-auto lg:mx-0 rounded-r-lg">
                                            <div
                                                className="absolute hidden lg:flex -right-40 bottom-0 w-20 h-20 rounded-2xl rotate-45 animate-rotate "
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #FF6606, #F79911)",
                                                }}></div>
                                        </div>

                                        <h2 className="font-bold leading-[1.2] md:text-2xl lg:text-4xl text-custom-blue pt-3 animate-fadeInUp opacity-1">
                                            {slide.description}
                                        </h2>
                                        <p className="mt-6 text-xl text-gray-900">{slide.text}</p>
                                        <div className="relative ">
                                            <Button
                                                type="search"
                                                className="mt-4 px-6 animate-fadeInDown py-2 rounded-lg text-white bg-gray-700"
                                                text="Explore More"
                                                onClick={() => handleButtonClick()}
                                            />
                                            <div
                                                className="absolute  hidden lg:flex lg:right-96 -bottom-24 w-20 h-20 rounded-2xl rotate-45 animate-rotate "
                                                style={{
                                                    background:
                                                        "linear-gradient(45deg, #FF6606, #F79911)",
                                                }}></div>
                                        </div>
                                    </div>

                                    {/* Right Side Content */}
                                    {!isSpecificResolution && (
                                        <div className="hidden lg:block col-span-12 lg:col-span-4 relative">
                                            <div className="absolute top-20 left-96 px-4 py-2 bg-gray-900 text-white text-sm rounded-full animate-fadeInDown">
                                                SEO
                                            </div>
                                            <div className="absolute top-[-150px] left-[-10px] px-4 py-2 bg-green-600 text-white text-sm rounded-full animate-fadeInDown">
                                                Development
                                            </div>
                                            <div className="absolute -top-8 -left-40 px-4 py-2 bg-red-400 text-white text-sm rounded-full animate-scroll">
                                                Branding
                                            </div>
                                            <div className="absolute bottom-44 right-3 px-4 py-2 bg-blue-900 text-white text-sm rounded-full animate-sparkle">
                                                Marketing
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
         
        </section>
    );
};

export default Banner;
 