import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const Linkedin = () => {
    const options = [
        {
          title: '1. Profile Optimization',
          description:
            ' Your LinkedIn profile is your digital business card. We will optimize your profile to bring out the strengths of your brand, ensuring that it aligns with your business goals and appeals to your target audience.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: '2. Content Strategy',
          description:'Compelling content is the backbone of any successful digital marketing agency in Udaipur  LinkedIn campaign. Our team crafts engaging posts, articles, and updates that showcase your expertise and keep your audience coming back for more.',
          description2 :''
        },
        {
            title: '3. Audience Targeting',
            description:"LinkedIn's advanced targeting options will help us connect you to the right people. Industry leaders, potential clients, or influencers - we will ensure that your message is delivered to the most relevant audience.",
            description2 :''
          },
          {
            title: '4. Ad Campaigns',
            description:'Be it sponsored posts or InMail campaigns, we design and execute ad campaigns on LinkedIn to bring measurable results. We keep every rupee accounted for with our data-driven approach.',
            description2 :''
          },
          {
            title: ' 5. Analytics and Reporting',
            description:"Understanding your campaign's performance is important. We provide in-depth analytics and reports that help track progress, measure ROI, and refine strategies for continuous improvement."

          },
          {
            title: 'Why Choose Takmon Boost?',
            description:"Takmon Boost is the leading digital marketing agency in Udaipur for LinkedIn marketing. Here's why:",
 description2 :'Local Expertise: We are based in Udaipur and understand the unique challenges and opportunities of the local market.'
          },
          {
            title: ' Customized Solutions:',
            description:"Customized Solutions: No one-size-fits-all for us. Every campaign will be created according to the needs of your business.",
 description2 :"Seasoned Team: Our team is composed of seasoned professionals who are very passionate about delivering results."
          },
          {
            title: 'Proven Track Record:',
            description:"Proven Track Record: We have helped numerous businesses achieve their LinkedIn marketing goals.We at Takmon Boost have been driven by excellence, which has given our clients outstanding success. One of our recent campaigns for a Udaipur-based tech company resulted in a 300% increase in profile views and a 40% boost in qualified leads within three months."

          },
          {
            title: 'Getting Started with LinkedIn Marketing',
            description:"LinkedIn marketing is an incredible tool for taking your brand to new heights. But it takes a level of expertise and strategic planning to really bring it all to life. That is where Takmon Boost comes in, being the most recognized digital marketing agency in Udaipur, to assist you at every step of the way-from profile optimization to targeted campaigns."

          },
          {
            title: '',
            description:"Want to advance your LinkedIn presence? Get in touch with Takmon Boost today and let us assist you in realizing your digital marketing goals."

          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Linkedin Marketing  "
       subHeading="Expand Your LinkedIn Network with Takmon Boost’s Innovative Marketing Solutions in Udaipur"
       description="In today's digital landscape, LinkedIn is a powerhouse for businesses looking to build a strong professional presence and connect with their target audience. Being the leading professional platform, LinkedIn offers an opportunity for businesses to network, showcase their expertise, and drive results. Takmon Boost, a premium digital marketing agency in Udaipur, specializes in utilizing LinkedIn marketing strategies to unlock the true potential of businesses.
"
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why LinkedIn Marketing Matters"
      description="LinkedIn is not just a social media platform; it’s a professional network with over 900 million users globally. This makes it the ideal place for businesses to:

Build Brand Awareness: Share insightful content to establish authority in your industry.

Generate Leads: Connect with decision-makers and expand your reach.

Drive Engagement: Foster meaningful conversations with a professional audience.

Recruit Talent: Attract top-tier professionals to join your team.

For businesses in Udaipur to expand their online reach, having a collaboration with an expert digital marketing agency such as Takmon Boost means your LinkedIn marketing is maximized for success.
"
      title2="How Takmon Boost Can Help"
      description2="As a reliable digital marketing agency in Udaipur, Takmon Boost gives you a customized approach to LinkedIn marketing. Here's how we make a difference:
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >Outsource SEO to a trustworthy SEO company such as Takmon Boost to dramatically boost your business's online presence and save time, money, and effort. Partnering with the best SEO company in Jaipur gets you top-level expertise, customized strategies, and support on an ongoing basis, and your website will rank and maintain high rankings. If you are a business looking to take your online presence to that next level, then Takmon Boost is probably your go-to.


</p></div>
      
  
     
  </>
  )
}

export default Linkedin
