// import React from "react";
// import commonbannerimg from "../assets/commonbannerimg.jpeg";

// // PlanCard Component (Reusable)
// const PlanCard = ({ title, price, description }) => {
//   // Extract numeric value from price using a regular expression
//   const numericPrice =
//     parseFloat(
//       price
//         .match(/[0-9,]+/g)
//         ?.join("")
//         .replace(",", "")
//     ) || 0;

//   const discountedPrice = Math.floor(numericPrice - numericPrice * 0.3); // Calculate 30% off and round down

//   return (
//     <div className="border border-orange-300 rounded-lg p-6 shadow-md text-center px-5">
//       <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
//       <div className="text-2xl font-semibold text-orange-500 mb-4 flex justify-center items-center gap-2">
//         {numericPrice > 0 && (
//           <>
//             <span>₹{discountedPrice}</span>
//             <span className="line-through text-gray-500 text-lg">
//               ₹{numericPrice}
//             </span>
//           </>
//         )}
//         {/* Show descriptive text from the original price */}
//         <span className="text-sm text-gray-600">
//           {price.replace(/^[₹0-9,.]+/, "").trim()}
//         </span>
//       </div>
//       <p className="text-gray-600 text-sm">{description}</p>
//     </div>
//   );
// };

// // Plans Component
// const Plans = () => {
//   const plansData = [
//     {
//       category: "Search Engine Optimization",
//       plans: [
//         {
//           title: "Full Brand",
//           price: "₹24,999",
//           description:
//             "Comprehensive SEO strategy to enhance your brand’s visibility and rankings.",
//         },
//         {
//           title: "On-Page",
//           price: "₹110/per page",
//           description:
//             "Optimizing website content and structure for better search engine rankings.",
//         },
//         {
//           title: "Off-Page",
//           price: "₹9,999",
//           description:
//             "Building backlinks and enhancing domain authority to improve search rankings.",
//         },
//       ],
//     },
//     {
//       category: "Social Media Optimizations",
//       plans: [
//         {
//           title: "Social Media Desgin",
//           price: "₹11,000",
//           description:
//             "Creating visually engaging social media designs to attract and engage audiences.",
//         },
//         {
//           title: "Social Media Handling",
//           price: "₹2,999",
//           description:
//             "Managing and optimizing your social media accounts to boost engagement and growth.",
//         },
//         {
//           title: "Social Media Optimizations",
//           price: "₹3,999",
//           description:
//             "Enhancing social media presence through targeted strategies and performance analysis.",
//         },
//       ],
//     },
//     {
//       category: "Ad",
//       plans: [
//         {
//           title: "Google Ad Campaign",
//           price: "₹7,999",
//           description:
//             "Drive traffic and conversions through targeted Google ad campaigns.",
//         },
//         {
//           title: "Meta Ad Campaign",
//           price: "₹7,999",
//           description:
//             "Boost brand visibility and engagement with Meta ad campaigns.",
//         },
//       ],
//     },
//     {
//       category: "Website Desgin ",
//       plans: [
//         {
//           title: "Coding",
//           price: "₹15,000",
//           description:
//             "Develop a custom, responsive website tailored to your business needs.",
//         },
//         {
//           title: "Wordpress",
//           price: "₹6,999/upto 10 pages",
//           description:
//             "Create a user-friendly website using WordPress, designed for easy content management.",
//         },
//       ],
//     },
//     {
//       category: " Ecommerce  Website Desgin ",
//       plans: [
//         {
//           title: "Coding",
//           price: "₹24,000",
//           description:
//             "Build a fully functional online store with seamless shopping experiences and secure transactions.",
//         },
//         {
//           title: "Wordpress",
//           price: "₹8,999 ",
//           description:
//             "Create a customizable e-commerce website using WordPress for easy product management and secure checkout.",
//         },
//         {
//           title: "Shopify Website",
//           price: "₹9,999",
//           description:
//             "Build a fully functional Shopify store with seamless integration for easy product sales and inventory management.",
//         },
//       ],
//     },
//     {
//       category: "APP Desgin ",
//       plans: [
//         {
//           title: "Shopping ",
//           price: "₹290,000",
//           description:
//             "Develop a feature-rich shopping app that offers seamless browsing, easy checkout, and secure payment options.",
//         },
//         {
//           title: "Service",
//           price: "₹9,9999 ",
//           description:
//             "Create an intuitive service app with booking, scheduling, and real-time service tracking features for users.",
//         },
//         {
//           title: "Finance",
//           price: "₹150,000",
//           description:
//             "Develop a secure finance app for budgeting, expense tracking, and managing financial transactions.",
//         },
//       ],
//     },
//     {
//       category: "Product Listing",
//       plans: [
//         {
//           title: "Amazon ",
//           price: "₹3490",
//           description:
//             "Create optimized product listings for Amazon with detailed descriptions, high-quality images, and relevant keywords for better visibility and sales.",
//         },
//         {
//           title: "Flipkart",
//           price: "₹24,00",
//           description:
//             "Craft well-optimized product listings for Flipkart with clear descriptions, attractive images, and targeted keywords to enhance visibility and boost sales.",
//         },
//         {
//           title: "other Platform",
//           price: "₹2,999",
//           description:
//             "Comprehensive product listing services for various platforms, ensuring your products reach a wider audience and are presented effectively.",
//         },
//       ],
//     },
//     {
//       category: "Editing",
//       plans: [
//         {
//           title: "Video ",
//           price: "₹999 /per min",
//           description:
//             "Professional video editing services to enhance your content with smooth transitions, effects, and high-quality finishing for all platforms.",
//         },
//         {
//           title: "Photo",
//           price: "₹149 /per Image",
//           description:
//             "Expert photo editing services to enhance images with color correction, retouching, and creative effects, perfect for personal or professional use.",
//         },
//         {
//           title: "Mockup",
//           price: "₹9,999",
//           description:
//             "Transform your designs into realistic product mockups, showcasing your work in professional settings for presentations and marketing.",
//         },
//         {
//           title: "Logo",
//           price: "₹999",
//           description:
//             "Enhance or redesign logos with creative edits, ensuring they align with your brand's identity and visual appeal.",
//         },
//         {
//           title: " 3d Animation",
//           price: "₹16,999",
//           description:
//             "Create stunning 3D animations to bring products, concepts, or ideas to life with realistic movements and effects.",
//         },
//         {
//           title: "Logo Animation",
//           price: "₹3490",
//           description:
//             "Transform your static logo into an engaging animation that reflects your brand's personality and catches attention.",
//         },
//       ],
//     },
//   ];

//   return (
//     <div className="text-gray-800 mt-16">
//       {/* Header Section */}
//       <div
//         className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center"
//         style={{ backgroundImage: `url(${commonbannerimg})` }}
//       >
//         <h2 className="text-4xl font-bold">Plan</h2>
//         <p className="text-sm mt-2">Home / plan</p>
//       </div>

//       {/* Plans Section */}
//       <div className="max-w-7xl mx-auto px-8 md:px-8 py-12">
//         {plansData.map((section, index) => (
//           <div key={index} className="mb-12">
//             <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
//               {section.category}
//             </h2>
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-9">
//               {section.plans.map((plan, idx) => (
//                 <PlanCard
//                   key={idx}
//                   title={plan.title}
//                   price={plan.price}
//                   description={plan.description}
//                 />
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Plans;



import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";

// PlanCard Component (Reusable)
const PlanCard = ({ title, price, description }) => {
  const numericPrice =
    parseFloat(
      price
        .match(/[0-9,]+/g)
        ?.join("")
        .replace(",", "")
    ) || 0;

  const discountedPrice = Math.floor(numericPrice - numericPrice * 0.3); // Calculate 30% off

  // Function to handle WhatsApp message
  const handleWhatsAppClick = () => {
    // const phoneNumber = "8824081228"; // Replace with your phone number
    // const message = `Hello! I am interested in your "${title}" plan priced at ₹${price}.`;
    // const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
    // // Use window.open to open WhatsApp in a new tab
    // window.open(whatsappUrl, "_blank");
    console.log("click the price card")
  };

  return (
    <div 
      className="border border-orange-300 rounded-lg p-6 shadow-md text-center px-5 cursor-pointer" 
      onClick={handleWhatsAppClick}
    >
      <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
      <div className="text-2xl font-semibold text-orange-500 mb-4 flex justify-center items-center gap-2">
        {numericPrice > 0 && (
          <>
            <span>₹{discountedPrice}</span>
            <span className="line-through text-gray-500 text-lg">
              ₹{numericPrice}
            </span>
          </>
        )}
        <span className="text-sm text-gray-600">
          {price.replace(/^[₹0-9,.]+/, "").trim()}
        </span>
      </div>
      <p className="text-gray-600 text-sm">{description}</p>
    </div>
  );
};

// Plans Component
const Plans = () => {
  const plansData = [
    {
      category: "Search Engine Optimization",
      plans: [
        {
          title: "Full Brand",
          price: "₹24,999",
          description:
            "Comprehensive SEO strategy to enhance your brand’s visibility and rankings.",
        },
        {
          title: "On-Page",
          price: "₹110/per page",
          description:
            "Optimizing website content and structure for better search engine rankings.",
        },
        {
          title: "Off-Page",
          price: "₹9,999",
          description:
            "Building backlinks and enhancing domain authority to improve search rankings.",
        },
      ],
    },
    {
      category: "Social Media Optimizations",
      plans: [
        {
          title: "Social Media Desgin",
          price: "₹11,000",
          description:
            "Creating visually engaging social media designs to attract and engage audiences.",
        },
        {
          title: "Social Media Handling",
          price: "₹2,999",
          description:
            "Managing and optimizing your social media accounts to boost engagement and growth.",
        },
        {
          title: "Social Media Optimizations",
          price: "₹3,999",
          description:
            "Enhancing social media presence through targeted strategies and performance analysis.",
        },
      ],
    },
    {
      category: "Ad",
      plans: [
        {
          title: "Google Ad Campaign",
          price: "₹7,999",
          description:
            "Drive traffic and conversions through targeted Google ad campaigns.",
        },
        {
          title: "Meta Ad Campaign",
          price: "₹7,999",
          description:
            "Boost brand visibility and engagement with Meta ad campaigns.",
        },
      ],
    },
    {
      category: "Website Desgin ",
      plans: [
        {
          title: "Coding",
          price: "₹15,000",
          description:
            "Develop a custom, responsive website tailored to your business needs.",
        },
        {
          title: "Wordpress",
          price: "₹6,999/upto 10 pages",
          description:
            "Create a user-friendly website using WordPress, designed for easy content management.",
        },
      ],
    },
    {
      category: " Ecommerce  Website Desgin ",
      plans: [
        {
          title: "Coding",
          price: "₹24,000",
          description:
            "Build a fully functional online store with seamless shopping experiences and secure transactions.",
        },
        {
          title: "Wordpress",
          price: "₹8,999 ",
          description:
            "Create a customizable e-commerce website using WordPress for easy product management and secure checkout.",
        },
        {
          title: "Shopify Website",
          price: "₹9,999",
          description:
            "Build a fully functional Shopify store with seamless integration for easy product sales and inventory management.",
        },
      ],
    },
    {
      category: "APP Desgin ",
      plans: [
        {
          title: "Shopping ",
          price: "₹290,000",
          description:
            "Develop a feature-rich shopping app that offers seamless browsing, easy checkout, and secure payment options.",
        },
        {
          title: "Service",
          price: "₹9,9999 ",
          description:
            "Create an intuitive service app with booking, scheduling, and real-time service tracking features for users.",
        },
        {
          title: "Finance",
          price: "₹150,000",
          description:
            "Develop a secure finance app for budgeting, expense tracking, and managing financial transactions.",
        },
      ],
    },
    {
      category: "Product Listing",
      plans: [
        {
          title: "Amazon ",
          price: "₹3490",
          description:
            "Create optimized product listings for Amazon with detailed descriptions, high-quality images, and relevant keywords for better visibility and sales.",
        },
        {
          title: "Flipkart",
          price: "₹24,00",
          description:
            "Craft well-optimized product listings for Flipkart with clear descriptions, attractive images, and targeted keywords to enhance visibility and boost sales.",
        },
        {
          title: "other Platform",
          price: "₹2,999",
          description:
            "Comprehensive product listing services for various platforms, ensuring your products reach a wider audience and are presented effectively.",
        },
      ],
    },
    {
      category: "Editing",
      plans: [
        {
          title: "Video ",
          price: "₹999 /per min",
          description:
            "Professional video editing services to enhance your content with smooth transitions, effects, and high-quality finishing for all platforms.",
        },
        {
          title: "Photo",
          price: "₹149 /per Image",
          description:
            "Expert photo editing services to enhance images with color correction, retouching, and creative effects, perfect for personal or professional use.",
        },
        {
          title: "Mockup",
          price: "₹9,999",
          description:
            "Transform your designs into realistic product mockups, showcasing your work in professional settings for presentations and marketing.",
        },
        {
          title: "Logo",
          price: "₹999",
          description:
            "Enhance or redesign logos with creative edits, ensuring they align with your brand's identity and visual appeal.",
        },
        {
          title: " 3d Animation",
          price: "₹16,999",
          description:
            "Create stunning 3D animations to bring products, concepts, or ideas to life with realistic movements and effects.",
        },
        {
          title: "Logo Animation",
          price: "₹3490",
          description:
            "Transform your static logo into an engaging animation that reflects your brand's personality and catches attention.",
        },
      ],
    },
  ];

  return (
    <div className="text-gray-800 mt-16">
      {/* Header Section */}
      <div
        className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center"
        style={{ backgroundImage: `url(${commonbannerimg})` }}
      >
        <h2 className="text-4xl font-bold">Plan</h2>
        <p className="text-sm mt-2">Home / plan</p>
      </div>

      {/* Plans Section */}
      <div className="max-w-7xl mx-auto px-8 md:px-8 py-12">
        {plansData.map((section, index) => (
          <div key={index} className="mb-12">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              {section.category}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-9">
              {section.plans.map((plan, idx) => (
                <PlanCard
                  key={idx}
                  title={plan.title}
                  price={plan.price}
                  description={plan.description}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
