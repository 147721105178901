import React from 'react';

const YourSteps = ({ steps, onStepClick }) => {
    return (
        <div className="bg-white font-Poppins min-h-screen">
            <div className="text-orange-500 bg-white text-center py-5 mt-3 text-2xl font-bold">
                \ Grow Your Business \
                <h2 className="text-gray-800 text-3xl font-bold">Our Steps To</h2>
            </div>
            <div className="text-center py-2">
                <div className="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 mt-0">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={`flex ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'} items-center p-6 cursor-pointer`}
                            onClick={() => onStepClick(step)}
                        >
                            <div className="flex-shrink-0 text-orange-500 text-3xl font-bold w-20 h-20 flex items-center justify-center border-4 border-custom-blue rounded-full">
                                {step.number}
                            </div>
                            <div className={`${index % 2 === 0 ? 'mr-6 text-right ' : 'ml-6 text-left'}`}>
                                <div className="text-lg font-semibold text-gray-800">{step.title}</div>
                                <div className="text-gray-600 mt-2 text-sm">{step.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default YourSteps;



