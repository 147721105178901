import React from 'react';

const IntroSection = ({ title2, title, description, description2 }) => {
  return (
    <div className="px-6 py-8 sm:px-20 sm:py-10 flex flex-col items-center sm:items-start sm:justify-between">
      {/* Title and Description Section */}
      <div className="text-center sm:text-left">
        <h1 className="text-lg sm:text-2xl font-bold mb-4">{title}</h1>
        <p className="text-gray-600 sm:text-gray-700 mb-6">{description}</p>
      </div>

      {/* Responsive Description Section */}
      <h1 className="text-2xl font-bold mb-4">{title2}</h1>
      <p className="text-gray-500 text-sm mb-2">{description2}</p>
    </div>
  );
};

export default IntroSection;
