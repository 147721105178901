import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const WebsiteMaintenance = () => {
    const options = [
        {
          title: 'How Takmon Boost Can Help',
          description:
            ' At Takmon Boost, we understand the importance of keeping your website in the best condition possible. Being one of the most rated website development companies in Jodhpur, our website maintenance services have been created to provide you with constant support in keeping your website secure, efficient, and up-to-date. We are dedicated to making sure your website provides the best user experience possible.We offer a wide range of maintenance services, which include the following:',
              description2 :'Content update, Security scans and updates, Performance enhancement, SEO analysis and optimization ,Backup management ,Mobile optimization',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Why Choose Takmon Boost?',
          description:'The right web development company in Jodhpur for your web maintenance services is where long-term success for your online presence will be achieved. Takmon Boost represents excellence, professionalism, and on-time service - each of which we can apply personally to every project to ensure that your website gets maintained at the highest standards..',
          description2 :'At Takmon Boost, you are in the best hands for leading web development companies in Jodhpur, providing the technical issues of your website to our expert developers. Free up your schedule for web development services and then run your business; do not worry, and rest assured, we take care of your website to boost business further.'
        },
        {
            title: 'Practical',
            description:"Maintenance in daily activity leads the performance level of your website to become perfect. With Takmon Boost as your trusted website development company in Jodhpur, you'll always receive a fresh and secure view with utmost performance from your site. Investing in professional website maintenance services is indeed an investment towards long-term success.",
            description2 :'If you are in search of a reliable website development company in Jodhpur to take care of your website maintenance, then you should contact Takmon Boost today. Let us help you keep your online presence strong and secure!'
          },
          
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Website Maintenance "
       subHeading="Website Maintenance: How to Make Online Presence with Takmon Boost, the Best Website Development Company in Jodhpur.
"
       description="The first interface whereby your business will be experienced is today via a website. For this very reason, it becomes all the more crucial that your website performs in smooth manners and loads swiftly, in addition to delivering highly exceptional user experiences. And it is in this regard where the importance of website maintenance assumes dominance. This will at the boost web site- that leads towards the top most website development company in Jodhpur is assured with excellent web maintenance services for up-to-date, secure, and working functionality."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why Website Maintenance Matters"
      description="Website maintenance is not about repairing broken links or updating content. This actually turns into a website management technique. Failure to handle properly can sometimes result in you losing ground; the appearance of security breaches and total loss through lost rankings with search pages. With our skilled web experts, here at Takmon Boost - A top developer for websites within Jodhpur - maintenance services were specifically curated so your site would shine at optimum capacity.
Takmon Boost: a reliable web development service company in Jodhpur will update your text, images, blogs, and other textural content keeping the audience interested in your website.
"
      title2=""
      description2="With the number of people accessing websites on mobile devices, it is no longer optional to have a website that is mobile-friendly. Takmon Boost is your reliable website development company in Jodhpur that provides ongoing maintenance so that your website is fully responsive on all devices, thus providing the best viewing experience to your users. A clean and healthy website provides smooth surfing along with the whole overall experience. By correcting dead links, updating content, and optimizing design, we increase the usability and bring the customers back again and again. As the best website development company in Jodhpur, Takmon Boost will always see to it that your website design and functionality are up to date."
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >Outsource SEO to a trustworthy SEO company such as Takmon Boost to dramatically boost your business's online presence and save time, money, and effort. Partnering with the best SEO company in Jaipur gets you top-level expertise, customized strategies, and support on an ongoing basis, and your website will rank and maintain high rankings. If you are a business looking to take your online presence to that next level, then Takmon Boost is probably your go-to.


</p></div>
      
  
     
  </>
  )
}

export default WebsiteMaintenance
