import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const Ecommerce = () => {
    const options = [
        {
          title: 'Important Services That Takmon Boost Offers',
          description:' Custom Ecommerce DevelopmentTakmon Boost is a leading website development company in Jaipur and offers custom-designed ecommerce websites with exclusive uniqueness based on business requirements. Solutions ranging from product catalogs to secure payment gateways, along with added functionalities of real-time inventory management are included. With cutting-edge technology implementation, your website will be developed on a growth path scaling according to the increasing demands of your business.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Responsive Web Design',
          description:'It needs that your ecommerce website be fully responsive to mobile devices. Takmon Boost, as one of the best website development companies in Jaipur, ensures that your ecommerce site is responsive; it automatically changes with the screen size. Therefore, customers can comfortably shop on your website, using either a phone, a tablet, or a desktop computer.',
          description2 :''
        },
        {
            title: 'SEO Optimization',
            description:'A pretty website is useless if nobody can find it. Takmon Boost knows the role of search engine optimization (SEO) in ecommerce success. Optimizing your site for search engines helps ensure your site ranks well on search results pages, drawing in more organic traffic and, subsequently, more sales.',
            description2 :''
          },
          {
            title: 'Secure Payment Integration',
            description:'Security is of prime importance in the context of ecommerce, and Takmon Boost equips your website with secure payment gateways. A customer wants to make a safe and hassle-free check out. Thus, the people working in Takmon Boost put efforts in integrating trusted payment solutions such as PayPal, Stripe, etc.',
            description2 :''
          },
          {
            title: ' Benefits of Partnering with Takmon Boost',
            description:'When you partner with Takmon Boost, you get much more than just a website development service. You get a dedicated team of experts working on your ecommerce success. This company focuses on creating user-friendly, fast, secure, and optimized websites for conversions. This focus helps you attract more customers, boost sales, and increase customer satisfaction.',
            description2 :'Cost-Effective: Being a leading website development company in Jaipur, Takmon Boost offers competitive pricing without compromising on quality. You get value for your investment while ensuring that your website performs optimally.Advanced technologies: Takmon Boost is always updated on the current trends and technologies within the ecommerce space. This way, your website remains competitive and future-proof.'
          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Ecommerce"
       subHeading="Ecommerce : Open Online success with Takmon Boost-The leader site building company in Jaipur."
       description="With the rapid digital world growing these days, businesses take ecommerce as one source of growth and the other in approaching and gaining worldwide customers. If you think you are going to have a web page, then it requires making a robust website friendly in handling. Takmon Boost - Jaipur's one of the website development company in Jaipur for its designing will be an added to your ecommerce business solution based on requirement.
."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why choose Takmon Boost, the best website development company in Jaipur?"
      description="The need for online shopping has been increasing and so has the importance of a well-designed and functional ecommerce website. Takmon Boost, the website development company in Jaipur, offers an array of services that can provide your business with the best possible edge in the competitive ecommerce market."
      title2=""
      description2="  Takmon Boost has highly professional developers, who know how to make the perfect seamless, secure, and responsive e-commerce sites. No matter the scale, whether a small or big business, Takmon understands how one would make the right site, both visually beautiful and highly user-friendly."
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >In the ecommerce-driven world of today, your website is the foundation on which your business thrives online. With Takmon Boost, the best website development company in Jaipur, you can rest assured that your ecommerce website is built to meet the highest standards of quality, security, and performance.

Take the first step towards transforming your online business today. Contact Takmon Boost, a trusted website development company in Jaipur, and unlock your ecommerce potential!


</p></div>
      
  
     
  </>
  )
}

export default Ecommerce
