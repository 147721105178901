import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const GoogleSearch = () => {
    const options = [
        {
          title: 'How to Run Successful Google Search Ads',
          description:
            'To ensure your investment in Google Search Ads pays off, here are some expert tips from Takmon Boost:Target Relevant Keywords: Using tools like Google Keyword Planner, identify terms like "Facebook ad agency in Jaipur" that your potential clients are searching for.Craft Compelling Ad Copy: Emphasize unique selling points such as "Top-rated Facebook Ad Experts in Jaipur" or "Budget-friendly Social Media Advertising ServicesOptimize Landing Pages: Drive users to a page that resonates with your ads message for higher conversions.Monitor and Adjust: Constantly check the performance metrics- CTR, CPC, conversion rates-to optimize your campaigns.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Why is Takmon Boost?',
          description:'Being one of the most trusted Facebook ad agencies in Jaipur, Takmon Boost offers customized strategies to assist businesses in achieving their objectives. With years of experience in Google and Facebook advertising, we:Create data-driven campaigns to bring maximum ROI.Competitive pricing for small and medium businesses.Personalized attention to meet your unique needs.',

          description2 :''
        },
       
      ];
  return (
    <>
    <ScoCommom
     
       heading="Google Search Ads  "
       subHeading="Google Search Ads for Maximum Impact."
       description="
Are you looking to outshine your competition by driving meaningful traffic to your business? Look no further. Google Search Ads is providing an unparalleled opportunity to be in front of your desired audience at the very exact moment they are searching for your Facebook ad agency in Jaipur  products and services. For businesses such as Takmon Boost - a premier Facebook ad agency based in Jaipur, Google search ads can complement your social media campaigns and maximize your overall digital marketing strategy.
"
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="What are Google Search Ads?"
      description="Google Search Ads are the paid advertisement ads that occur on the search engine result page, once a search is entered based on some keywords. This ad search will ensure it matches up with the user intent such that your business will be delivered to the correct target market. As a Facebook ad agency in Jaipur or another related digital marketing solution, this one certainly beats them in the number of visits with regard to its ROI."
      title2="Why Invest Google Search Ads for Your Facebook Ad Agency in Jaipur?
"
      description2="Takmon Boost works exclusively to make businesses bloom in social media, and associating your efforts with Google Search Ads can raise your success to a more amplified degree. Why is that?
      People actively searching on Google, means they are looking out for the solution. Your ad would strike at the right moment. Higher Conversion.
      Broader Reach: Whereas Facebook is very good with targeted audiences, Google captures those individuals outside of social networks which expands your customer reach.

Performance Insights: Deep analysis from Google Ads is essential to optimize campaigns so cost and results are optimized.

Competitive Advantage: Most businesses in Jaipur depend on only an organic search or social media campaign. With Google Search Ads integration, you will be ahead of the curve.
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >Google Search Ads transform how your business attracts and converts customers. For a business like Takmon Boost, combining Google Ads with professional Facebook advertising ensures completely integrated and results-driven campaigns. Ready to boost your business? Work with Takmon Boost: one of the top Facebook ad agencies in Jaipur  Facebook advertising companies in Jaipur, leading your business to the power of integrated digital marketing strategies.


</p></div>
      
  
     
  </>
  )
}

export default GoogleSearch
