import React from 'react'
import BannerSection from '../SeoServices/BannerSection'
import severs from "../../assets/Services/severs.png";
import OurPartner from '../OurPartner';

import { useNavigate } from 'react-router-dom';
import YourSteps from '../YourSteps';
const PpcServices = () => {
    const navigate = useNavigate();

    const steps = [
        { number: '01', title: 'Google Ads Management ', description: "In today's highly competitive digital marketplace, it has become vital for achieving business growth by having an effective online advertising strategy  Google Ads stands out as one of the most powerful .... ", route: '/services/ppc/google_adds' },
        { number: '02', title: 'Google Search Ads ', description: 'Are you looking to outshine your competition by driving meaningful traffic to your business? Look no further. Google Search Ads is providing an unparalleled opportunity to be in front of your desired audience....', route: '/services/ppc/google_Search' },
        { number: '03', title: 'Video Ads Campaign                                      ', description: "Video ads campaigns are one of the most potent tools in the world of digital marketing. With increased popularity of social media like Facebook, Instagram, and YouTube, it has been a very important ", route: '/services/ppc/Video_Campaign' },
        { number: '04', title: 'Benefits of Video Ads in Your Social Media Ad Campaign', description: 'More engagement: Video ads have a much higher engagement rate than other forms of content. They Social Media Ad Campaign Service in Jaipur are ', route: '/services/ppc/Video_Campaign' },
       
    ];

    const handleStepClick = (step) => {
        if (step.route) {
            navigate(step.route);
        } else {
            console.log(`Clicked step: ${step.title}`);
        }
    };
  return (
    <div>
    <BannerSection
        title="PPC Advertising Services"
        description="
Takmon Boost is a Jaipur-based online lead generation company that provides the most effective PPC services to bring in targeted traffic and convert the same. Our tailored strategies ensure maximum ROI for the business to help it achieve its marketing goals. Partner with us today and unlock the full potential of pay-per-click advertising!
"
        mainImage={severs}
        overlayImage={severs}
      />
     <YourSteps steps={steps} onStepClick={handleStepClick} />
      <OurPartner/>
    </div>
  )
}

export default PpcServices
