import React from 'react'

const ScoCommom = ({
    badgeText,
    heading,
    subHeading,
    description,
    primaryButtonText,
    primaryButtonLink,
    secondaryButtonText,
    secondaryButtonLink,
    imageSrc,
    imageAlt,
  }) => {
    return (
        <section className=" bg-[#FC790A]  py-16">
        <div className="container mx-auto flex flex-col md:flex-row items-center px-5 py-10">
          {/* Text Content */}
          <div className="w-full md:w-1/2 text-center md:text-left">
           
            <h1 className="text-4xl font-bold mb-4 animate-fadeInLeft">{heading}</h1>
            <p className="text-lg font-semibold mb-4">{subHeading}</p>
            <p className="text-base mb-6">{description}</p>
            <div className="flex justify-center md:justify-start gap-4">
              {/* <a
                href={primaryButtonLink}
                className="bg-red-600 text-white py-2 px-6 rounded-lg hover:bg-red-700 transition"
              >
                {primaryButtonText}
              </a> */}
              <a
                href={secondaryButtonLink}
                 className="mt-4 px-6 animate-fadeInDown py-2 rounded-lg text-white bg-gray-700"
              >
                {secondaryButtonText}
              </a>
            </div>
          </div>
  
          {/* Image Content */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <img
              src={imageSrc}
              alt={imageAlt}
              className="rounded-lg shadow-lg mx-auto"
            />
          </div>
        </div>
      </section>
    );
  };

export default ScoCommom
