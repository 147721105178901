import React from 'react'
import patners from '../../assets/Services/severs.png'
import ScoCommom from '../NestedCommon/ScoCommom'

import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard'

const LocalBusinessSEO = () => {
  const options = [
    {
      title: 'Google My Business Optimization',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem IGoogle My Business is one of the best tools for local SEO, whereby its optimized profile ensures that your business always shows up in the Google search and Google Maps as appearing within the local pack. Takmon Boost helps you optimize completely because it makes sure the following information is correct and complete: business name, address, phone number, website, and business hours. We also add interesting content and images to make your profile stand out, boosting local visibility.',

    },
    {
      title: 'On-Page SEO for Local Businesses',
      description:' Takmon Boost involves an improvement in the relevance of your website to local search queries by implementing on-page SEO techniques. This simply means optimizing title tags, meta descriptions, headers, and content so that it includes location-based keywords. More than that, we make sure that your website is user-friendly and search engine-friendly so that the search engines can index your site efficiently.',
      description2 :'Why Choose Takmon Boost as Your SEO Company in India?When it comes to choosing an SEO company in India, it’s important to work with a team that understands local SEO strategies and knows how to implement them effectively. Takmon Boost stands out because:'
    },
 
  ];
  return (
    <>
      <ScoCommom
       
         heading="Local Business SEO  Services"
         subHeading="Local Business SEO: Increase your visibility with Takmon Boost – leading Indian SEO Company"
         description="These days, local businesses desperately need to cut through this ever-increasing list of online contenders. Being either a small player or an old-established one, a strong presence online serves to get the local buyers attracted towards your business. It is now where a local SEO company in India can change the way your business will look online and, ultimately, give you that success you have always wished to attain within your local market."
        //  primaryButtonText="Request Proposal"
        //  primaryButtonLink="#request-proposal"
         secondaryButtonText="SEO Packages"
         secondaryButtonLink="/ourPlan"
         imageSrc={patners}
         imageAlt="Pest Control SEO Services"/>
           
        
     
        <IntroSection
        // breadcrumb="Home / Services"
        title="Why Local SEO Matters to Your Business"
        description="Local SEO is the practice of optimizing your website to show up in local search results. Your business must rank high in a search engine result page for relevant services or products offered, because the potential customer must find what he is looking for and at what location he must visit. Local SEO practices try to make your site relevant for location-specific keyword terms. As the growth in mobile and online searching will keep on increasing to reach local businesses, therefore this will become an integral practice of a business that gains success.Working with an expert SEO company in India such as Takmon Boost gives you expert support, especially designed for growing your business and will also help rank your company higher in the local search results, web traffic increases, and finally, sales and conversion boost."
        title2="How Takmon Boost Can Help Your Business"
        description2="At Takmon Boost, we are focused on providing customized local SEO services that can meet the needs of your business. As a well-established SEO company in India, we understand that no two businesses are alike and that every business requires a specific strategy to reach its target audience. Some of the key local SEO services that we provide include:"
      />
       <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
        {options.map((option, index) => (
          <SeoCard
            key={index}
            title={option.title}
            description={option.description}
            description2={option.description2}

            bgColor={option.bgColor}
          />
        ))}
       
      </div>
      <div className='px-20 py-10 items-center justify-between '>
        <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
        <p className="text-gray-700 text-sm" >Investing in local SEO is perhaps one of the smartest decisions any business can take to drive more foot traffic, increase online engagement, and build brand awareness in the local market. Takmon Boost, an SEO company in India, is devoted to providing local SEO services to boost your business's visibility and lead generation.

Contact us today to understand how our customized local SEO can help your business dominate all local search results and bring more customers inside your doors. Let's have Takmon Boost as our partner for success!
</p></div>
        
    
       
    </>
  )
}

export default LocalBusinessSEO
