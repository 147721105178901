import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import aboutus123 from "../../assets/About us/aboutusimage1-removebg-preview.png";


const BlogsPage = () => {
    const location = useLocation();
    const selectedBlog = location.state?.blog || null; // Access blog from state or fallback to null

    if (!selectedBlog) {
        return <div>No blog selected</div>;
    }
    return (
        <div className="bg-gray-50 text-gray-800 mt-[64px]">
            {/* Header Section */}

            {/* Main Blog Section */}

            <div className="max-w-screen-lg mx-auto py-24 px-5 ">
                {/* <div className="relative">
                    <div className="rounded-md absolute w-24 h-24 lg:left-3 lg:bottom-[23rem] md:bottom-24 md:left-6 z-0">
                        <img
                            src={aboutus123}
                            alt="Decoration"
                            className="w-24 h-36"
                        />
                    </div>
                    <div className="rounded-md lg:w-36 lg:h-36 bg-custom-orenge absolute lg:left-12 lg:bottom-72 md:left-14 md:bottom-14 md:w-36 md:h-36 z-10"></div>
                    <div className="relative z-20 px-16">
                        {selectedBlog && selectedBlog.image ? (
                            <img
                                src={selectedBlog.image}
                                alt={selectedBlog.title}
                                className=""
                            />
                        ) : (
                            <p>No image available</p>  // You can show a fallback message or image
                        )}
                    </div>
                    <div className="rounded-md w-24 h-24 absolute lg:top-[20rem] right-[134px]  z-0">
                        <img
                            src={aboutus123}
                            alt="Decoration"
                            className="w-24 h-36"
                        />
                    </div>
                    <div className="rounded-md lg:w-36 lg:h-36 md:bottom-10 md:w-32 md:h-32 bg-custom-gray absolute sm:right-[-12px] lg:right-[169px] lg:top-[18rem] z-10"></div>
                </div> */}
                <div className="relative">
                <div className="relative z-20 px-16 ">  
                {selectedBlog && selectedBlog.image ? (
                <img
                                src={selectedBlog.image}
                                alt={selectedBlog.title}
                                className="rounded-lg md:w-[50rem] md:h-[16rem] "
                            />  ) : (
                                <p>No image available</p>  // You can show a fallback message or image
                            )}

                </div>
                <div className="rounded-md md:w-36 md:h-36 bg-custom-orenge absolute z-10 lg:bottom-[7.7rem] md:left-12 sm:-left-3 sm:-top-3 hidden sm:block md:block "></div>
    
                <div className="rounded-md w-24 h-24 absolute bottom-52 left-5 hidden sm:block md:block z-0">
                        <img
                            src={aboutus123}
                            alt="Decoration"
                            className=""
                        />
                    </div>
                    <div className="rounded-md w-24 h-24 absolute top-[9rem] lg:right-[70px] md:right-[18px]  hidden sm:block md:block z-0">
                        <img
                            src={aboutus123}
                            alt="Decoration"
                            className=""
                        />
                    </div>
                    <div className="rounded-md md:w-36 md:h-36 bg-custom-gray absolute  lg:right-[105px] top-[8rem] md:right-[50px] hidden sm:block md:block z-10"></div>
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mt-14 px-5">
                    {selectedBlog.title}
                </h1>

                <p className="text-gray-700 text-lg leading-7 px-5">
                    <span className="font-bold">{selectedBlog.title}</span>
                    <br />
                    <br />
                    {selectedBlog.fullContent.split("\n").map((paragraph, index) => (
                        <p key={index} className="mb-4">{paragraph}</p>
                    ))}
                </p>
            </div>
        </div>
    );
};

export default BlogsPage;
