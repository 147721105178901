import React from 'react'

import BannerSection from '../SeoServices/BannerSection'
import severs from "../../assets/Services/severs.png";
import OurPartner from '../OurPartner';

import { useNavigate } from 'react-router-dom';
import YourSteps from '../YourSteps';
const SmmService = () => {
 const navigate = useNavigate();

    const steps = [
        { number: '01', title: 'Facebook Marketing  ', description: "In this digital-first world, Facebook marketing is the foundation of any business looking to maximize its online presence and engage with the target audience more effectively. As a leader in performance marketing companies in Jaipur, Takmon Boost .... ", route: '/services/ppc/google_adds' },
        { number: '02', title: 'Instagram Marketing ', description: 'In today’s fast-paced digital era, businesses are increasingly turning to Instagram to elevate their online presence. Instagram, with its vast user base and visual-centric platform, offers unparalleled opportunities for brands to connect with their audience. ....', route: '/services/ppc/google_Search' },
        { number: '03', title: 'YouTube Marketing                                     ', description: "In today's digital landscape, YouTube has emerged as one of the most powerful platforms for businesses and individuals to connect with their target audience. With over 2 billion active users worldwide, leveraging ...", route: '/services/ppc/Video_Campaign' },
        { number: '04', title: 'Linkedin Marketing ', description: "In today's digital landscape, LinkedIn is a powerhouse for businesses looking to build a strong professional presence and connect with their target audience. Being the leading professional platform, LinkedIn offers an opportunity for businesses..", route: '/services/ppc/Video_Campaign' },
        { number: '05', title: 'Twitter Marketing  ', description: "The digital world is ever accelerating. Hence, today, any effective marketing strategy cannot afford to not be inclusive of Twitter marketing. To gain optimum use of the online growth opportunity of a business, one must have a planned approach toward using Twitter. Therefore, if you want to grow your brand's volume on Twitter...", route: '/services/ppc/Video_Campaign' },
    ];

    const handleStepClick = (step) => {
        if (step.route) {
            navigate(step.route);
        } else {
            console.log(`Clicked step: ${step.title}`);
        }
    };
  return (
    <div>
    <BannerSection
        title="SMM Services"
        description="
Takmon Boost, a leading performance marketing company in Jaipur offers its best SMM for Branding to give an exceptional boost to your brands. Our strategies are created especially to maximize engagement on those platforms, with measurably driven results. Let's make an online presence change and set amazing growth in this busy competitive digital world together.

"
        mainImage={severs}
        overlayImage={severs}
      />
     <YourSteps steps={steps} onStepClick={handleStepClick} />
      <OurPartner/>
    </div>

  )
}

export default SmmService
