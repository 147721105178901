import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const VideoAdd = () => {
    const options = [
        {
          title: 'Benefits of Video Ads in Your Social Media Ad Campaign',
          description:
            ' More engagement: Video ads have a much higher engagement rate than other forms of content. They Social Media Ad Campaign Service in Jaipur are likely to be watched, shared, and interacted with by users on platforms such as Facebook, Instagram, and YouTube. More engagement helps you reach a wider audience.',
              description2 :"Targeted Advertising: Takmon Boost's social media ad campaign service in Jaipur enables you to reach the right target audience based on demographics, interests, and behaviors. Advanced targeting on social media will ensure your video ads reach people most likely to convert into paying customers.",
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'How Takmon Boost Helps You Succeed',
          description:'Takmon Boost is not only a video ad creation service but it is a full-fledged social media ad campaign service in Jaipur that involves strategic planning for campaigns, video production, and also audience targeting along with performance analysis. The experts working with you understand the business objectives of every video ad campaign created by them to be in tandem with your business goals.',
        
        },
   
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Video Ads Campaign  "
       subHeading="Video Ads Campaign: Enhance Your Business through Takmon Boost's Social Media Ad Campaign Service in Jaipur"
       description="
Video ads campaigns are one of the most potent tools in the world of digital marketing. With increased popularity of social media like Facebook, Instagram, and YouTube, it has been a very important tool for those businesses that want to make a name. If you are in Jaipur and are looking for professional yet effective means to market your business, then the social media ad campaign service by Takmon Boost's Social Media Ad Campaign Service in Jaipur in Jaipur is just the right solution for you."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why Video Ads For Your Social Media Ad Campaign?"
      description="Video commercials aren't just very visually appealing, but a clear and concise message comes directly through to your audience. Since this is not the traditional style of advertising, they will be able to create the right impression. A nicely crafted video can narrate a story, provoke some emotions, and display the importance of your products or services as would not be possible in print or text form.

Video advertisements were shown to strongly engage your target audience. Researches in recent times found that video content was shared 1200% more than a combination of both text and images. That kind of engagement rate would translate to more exposure, which means it is highly important to the business's growth. The social media ad campaign service at Takmon Boost Jaipur ensures that you make video advertisements that speak directly to your audience, helping you attain better leads and conversions.
"
      title2="Takmon Boost: Your social media ad campaign partner."
      description2="Takmon Boost is Jaipur's leading provider for social media ad campaign services. With expertise in offering customized video ads, these people assist you in achieving your specific business requirements. Whether small or huge, Takmon Boost would ensure strategic design of video advertisements to maximize reach and engagement for your business.The deep understanding of the local market is one of the most important benefits that one will derive by choosing Takmon Boost for the video ads campaign. They have deep expertise in the Jaipur region, which they implement into strategies according to your target audience's preferences and behaviors. It means the video ads will impact as desired to increase brand awareness and sales.
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >Today's competitive digital landscape is the right opportunity to engage your audience with a video ads campaign and drive business growth. With social media ad campaign service in Jaipur from Takmon Boost, you can create compelling video ads that resonate with your target audience, boost brand awareness, and generate more conversions. Their expertise and commitment to delivering results make them the ideal partner for your video ads campaign in Jaipur.


</p></div>
      
  
     
  </>
  )
}

export default VideoAdd
