import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/LandingPage/Home'
import Layout from './Layout'
import AboutUs from '../components/AboutUs'
import TermsCondition from '../components/TermsCondition'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Services from '../components/Services'
import ContactUs from '../components/ContactUs'
import Blogs from '../components/Blogs'
import Packages from '../components/Packages'
import BlogsPage from '../components/Blogs/BlogsPage'
import OfferPlans from '../components/LandingPage/Home/OfferPlans'

import Plans from '../components/Plans'
import AffiliateHome from '../components/Affiliate/AffiliateHome'

import LocalBusinessSEO from '../AllinOneServices/SeoServices/LocalBusinessSEO'
import OutsourceSeo from '../AllinOneServices/SeoServices/OutsourceSeo'
import SmallBusinessseo from '../AllinOneServices/SeoServices/SmallBusinessseo'
import OnPageSeo from '../AllinOneServices/SeoServices/OnPageSeo'
import OffPageSeo from '../AllinOneServices/SeoServices/OffPageSeo'
import TechnicalSeo from '../AllinOneServices/SeoServices/TechnicalSeo'
import GoogleAds from '../AllinOneServices/PpcServices/GoogleAds'
import GoogleSearch from '../AllinOneServices/PpcServices/GoogleSearch'
import FacebookMarketing from '../AllinOneServices/SmmServices/FacebookMarketing'
import InstagramService from '../AllinOneServices/SmmServices/InstagramService'
import VideoAdd from '../AllinOneServices/PpcServices/VideoAdd'
import YouTube from '../AllinOneServices/SmmServices/YouTube'
import Linkedin from '../AllinOneServices/SmmServices/Linkedin'
import TwitterMarketing from '../AllinOneServices/SmmServices/TwitterMarketing'
import WordPress from '../AllinOneServices/WebDesignService/WordPress'
import Ecommerce from '../AllinOneServices/WebDesignService/Ecommerce'
import CustomWebsite from '../AllinOneServices/WebDesignService/CustomWebsite'
import WebsiteMaintenance from '../AllinOneServices/WebDesignService/WebsiteMaintenance'
import PpcServices from '../AllinOneServices/PpcServices/PpcServices'
import SeoServices from '../AllinOneServices/SeoServices/SeoServices'
import SmmService from '../AllinOneServices/SmmServices/SmmService'
import WebDesignService from '../AllinOneServices/WebDesignService/WebDesignService'
import SocialMManagement from '../AllinOneServices/SocialMManagement'
import SmoServices from '../AllinOneServices/SmoServices'
import CreateAffilate from '../components/Affiliate/CreateAffilate'


const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout> <Home /> </Layout>} />
        <Route path='/about' element={<Layout> <AboutUs /> </Layout>} />
        <Route path='/services' element={<Layout> <Services /> </Layout>} />
        <Route path='/services/seo/local-business' element={<Layout> <LocalBusinessSEO/></Layout>} />
        <Route path='/services/seo/outsource' element={<Layout> <OutsourceSeo/></Layout>} />
        <Route path='/services/seo/small-business' element={<Layout> <SmallBusinessseo/></Layout>} />
        <Route path='/services/seo/onpage' element={<Layout> <OnPageSeo/></Layout>} />
        <Route path='/services/seo/offpage' element={<Layout> <OffPageSeo/></Layout>} />
        <Route path='/services/seo/technical' element={<Layout> <TechnicalSeo/></Layout>} />
        <Route path='/services/ppc/google_adds' element={<Layout> <GoogleAds/></Layout>} />
        <Route path='/services/ppc/google_Search' element={<Layout> <GoogleSearch/></Layout>} />
        <Route path='/services/ppc/Video_Campaign' element={<Layout> <VideoAdd/></Layout>} />
    
        <Route path='/services/smm/facebook' element={<Layout> <FacebookMarketing/></Layout>} />
        <Route path='/services/smm/instagram' element={<Layout> <InstagramService/></Layout>} />
        <Route path='/services/smm/youtube' element={<Layout> <YouTube/></Layout>} />
        <Route path='/services/smm/linkedin' element={<Layout> <Linkedin/></Layout>} />
        <Route path='/services/smm/twitter' element={<Layout> <TwitterMarketing/></Layout>} />
        <Route path='/services/web-design/wordpress' element={<Layout> <WordPress/></Layout>} />
        <Route path='/services/web-design/ecommerce' element={<Layout> <Ecommerce/></Layout>} />
        <Route path='/services/web-design/custom' element={<Layout> <CustomWebsite/></Layout>} />
        <Route path='/services/web-design/maintenance' element={<Layout> <WebsiteMaintenance/></Layout>} />
       
        <Route path='/services/ppc' element={<Layout> <PpcServices/></Layout>} />
        <Route path='/smm/services' element={<Layout> <SmmService/></Layout>} /> 
        <Route path='/services/smo' element={<Layout> <SmoServices/></Layout>} />
        <Route path='/services/web-design' element={<Layout> <WebDesignService/></Layout>} />
        <Route path='/services/social-media' element={<Layout> <SocialMManagement/></Layout>} />







        <Route path='/seo/services' element={<Layout> <SeoServices/></Layout>} />

        <Route path='/contact' element={<Layout> <ContactUs /> </Layout>} />
        <Route path='/termCondtion' element={<Layout> <TermsCondition /> </Layout>} />
        <Route path='/blogs' element={<Layout> <Blogs/> </Layout>} />
        <Route path='/package' element={<Layout> <Packages/></Layout>} />
        <Route path='/ourPlan' element={<Layout> <Plans/></Layout>} />
      
        <Route path='/privacyPolicy' element={<Layout> <PrivacyPolicy/> </Layout>} />
        <Route path='/blogsPage' element={<Layout> <BlogsPage/> </Layout>} />
        <Route path='/offer' element={<Layout> <OfferPlans/></Layout>} />

        
        <Route path='/affiliate' element={<Layout> <CreateAffilate/>  </Layout>} />
        {/* <Route path='/affiliate' element={<Layout> <CreateAffilate/> </Layout>} /> */}






      </Routes>
    </>
  )
}

export default AppRoutes
