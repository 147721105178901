import React from 'react'
import BannerSection from '../SeoServices/BannerSection'
import severs from "../../assets/Services/severs.png";
import OurPartner from '../OurPartner';

import { useNavigate } from 'react-router-dom';
import YourSteps from '../YourSteps';
const WebDesignService = () => {
    const navigate = useNavigate();

    const steps = [
        { number: '01', title: 'WordPress ', description: "Having the best online presence is indispensable in today's competitive digital landscape. At Takmon Boost, it's a leading WordPress website development company in Jodhpur.... ", route: '/services/web-design/wordpress' },
        { number: '02', title: 'Ecommerce', description: 'With the rapid digital world growing these days, businesses take ecommerce as one source of growth and the other in approaching and gaining worldwide customers. If you think you are going to have a web page, ....', route: '/services/web-design/ecommerce' },
        { number: '03', title: 'Custom Website                                    ', description: "In today's digital age, a custom website is more than a virtual presence; it is a vital means for businesses to engage their audience, showcase their products, and establish their brand identity. Therefore, if you plan to build a custom website,...", route: '/services/web-design/custom' },
        { number: '04', title: 'Website Maintenance  ', description: "The first interface whereby your business will be experienced is today via a website. For this very reason, it becomes all the more crucial that your website performs in smooth manners and loads swiftly, in addition to delivering highly..", route: '/services/web-design/maintenance' },
       
    ];

    const handleStepClick = (step) => {
        if (step.route) {
            navigate(step.route);
        } else {
            console.log(`Clicked step: ${step.title}`);
        }
    };
  return (
    <div>
    <BannerSection
        title="Web Development"
        description="
Takmon Boost is the best company to hire for reliable website development in Jaipur. We specialize in innovative and user-friendly websites created specifically according to your business needs. Take your business to new heights with the top-notch digital solutions of Takmon Boost, a leading website development company in Jaipur.

"
        mainImage={severs}
        overlayImage={severs}
      />
     <YourSteps steps={steps} onStepClick={handleStepClick} />
      <OurPartner/>
    </div>
  )
}

export default WebDesignService
