
import React, { useState } from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import sco1 from "../assets/Blogs/sco1.jpeg";
import sco3 from "../assets/Blogs/sco3.jpeg";

import aboutus123 from "../assets/About us/aboutusimage1-removebg-preview.png";
import Button from "./common/Button";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleClick = (blog) => {
    setSelectedBlog(blog);
    navigate("/blogsPage", { state: { blog } });
    console.log(setSelectedBlog, "setSelectedBlog");

  };

  const blogData = [
    {
      title: "Why Digital Marketing Services in India Are Transforming the Business World?",
      description: "In today's fast-paced digital era, businesses need to adapt to stay relevant, competitive, and profitable. One of the most significant changes in recent years has been the rise of digital marketing as a powerful tool for growth digital marketing services in India . Among the global leaders in this domain, India has emerged as a hub for innovative and cost-effective digital marketing services. Companies like Takmon Boost are driving this transformation, helping businesses across industries thrive in the digital age.If you’re exploring the world of digital marketing services in India, this blog sheds light on why they are becoming a game-changer in the global business landscape.",

      fullContent: `The Rise of Digital Marketing in India
The digital ecosystem of India has expanded exponentially in the last decade. With 700 million internet users, along with an increase in penetration of smartphones, the nation has emerged as a breeding ground for businesses to access a massive audience. Leverage this advantage by digital marketing services in India to craft the most targeted campaigns that lead to measurable results.

This rapid growth has urged Indian digital marketing agencies to use cutting-edge technologies, analytics, and creative strategies to stay competitive in the global market. Companies such as Takmon Boost stand at the top, providing customized solutions that answer the special needs of a business.

Why Digital Marketing Services in India Stand Out
1. Cost-Effectiveness Without Compromising Quality
One of the most popular reasons businesses opt for India digital marketing services is the affordability that high-class solutions offer. An Indian agency will provide competitive pricing compared to another while maintaining exceptional quality standards, which makes India so attractive in a market for startups or small companies, even enormous corporations interested in maximizing returns on their investments.

2. Expertise across diverse industries:
Indian digital marketing professionals have the widest range of experience working with the widest possible industry. From e-commerce to healthcare, education, and finance, Indian agencies like Takmon Boost understand the nuances of each sector, and this versatility helps craft strategies that resonate with a particular audience.

3. Tech-Driven Innovation
IT strength: India's strong IT backbone is one of the key success drivers for digital marketing. Most agencies in the country can jump on the bandwagon about emerging trends such as artificial intelligence (AI), machine learning (ML), and data analytics. These are employed in optimizing campaigns, improving targeting, and delivering more tailored customer experiences.

4. Outcome-Focused Strategies
A characteristic of the India digital marketing service provision process is the focus on delivering actual measurable performance. Agencies such as Takmon Boost ensure that clients focus more on measurable performances through aspects such as website traffic improvements and better search rankings. However, it would culminate in lead conversion rates and provide value to a customer on investment.

The Major Features of Digital Marketing Services in India
To understand why digital services are transformative, their basic parts need to be studied for proper understanding.

1. SEO:
Indian agencies optimize the websites with an excellence which rank better in the search engine with the increasing organic traffic and visibility. Through keyword research, on-page optimization, and link building strategy, they have sustained growth in their rankings.

2. Pay-Per-Click Advertising (PPC):
Indian agencies have become a source of high-demand services, in terms of PPC campaigns. Well-planned and executed to drive quality traffic, the purpose is always a better return on ad spend (ROAS).

3. SMM: 
With the presence of Instagram, Facebook, and LinkedIn, brand promotion is indispensable. Indian agencies assist businesses with social media marketing, developing meaningful social media strategies that establish relationships and connect with customers.

4. Content Marketing:
The Digital Kingdom remains to be governed by Content, and Indian Agencies grasp that perfectly. Whether in a blog, an article, videos, or infographics, content marketing campaigns are strategized to educate, engage, and convert leads and prospects.

5. Email Marketing:
Indian Email Marketing Campaigns are always niche based and provide maximum engagement. Here, Indian Agencies draft well-suited personalized emails for a Lead's nurturing process and a continued engagement of existing customers with them.

Global Demand of Digital Marketing Services in India
Indian digital marketing companies have attracted the attention of most businesses globally because they are able to balance creativity with technical skills. Companies such as Takmon Boost not only serve domestic clients but help international businesses achieve their dreams.

India's time-zone advantage also means that it's possible to offer support around the clock and complete projects much more quickly, making collaboration convenient for clients from different ends of the world.

Why Choose Takmon Boost?
At Takmon Boost, we stand out as a trusted partner for businesses in search of transformational digital marketing services in India. The approach is one of listening to our clients' individual needs and developing strategies for long-term success. Be it SEO and social media to performance marketing and more, our approach brings innovation, expertise, and commitment to every project.

Conclusion
The business world is witnessing a paradigm shift, and digital marketing forms the core of this change. India, with its vast talent pool, technological edge, and cost-effective solutions, has established itself as a global leader in the field. For businesses looking to scale new heights, partnering with an Indian agency like Takmon Boost can be a game-changer.

`,
      image: sco1,
      alignLeft: true,
    },

    {
      title: "How a Web Development Company in India Can Lift Up Your Brand Online?",
      description: "It's the digital age, and there are no two ways about how having an effective online presence matters in terms of delivering one's brand. Whether a start-up or an enterprise brand, having a professionally designed and developed website can significantly alter one's brand's direction of success. The best collaboration your business can have in a market like India would be to team up with a web development company, and Takmon Boost can prove to be that game-changer. Here is how that can help boost your brand online.",
      fullContent: `Custom website solutions, tailored to the client's requirements

A one-size-fits-all approach hardly ever works for websites. Every brand has its specific needs and objectives, and a Web Development Company in India can deliver custom solutions that will fit perfectly into your vision. Takmon Boost specializes in understanding your business, audience, and objectives to create a website that speaks directly to your customers.

From designing visually appealing layouts to incorporating all the essentials, these custom solutions will make your website a real powerhouse for engaging and converting customers.

Cost-Effective Services Without Compromise on Quality

India has emerged as a web development center for top-notch yet price-effective services. Entities such as Takmon Boost use competent professionals and sophisticated technologies to create solutions to fit your budget. Partnering with a Web Development Company in India lets you maximize your ROI without sacrificing on quality.

Whether you need an e-commerce platform, a corporate website, or a dynamic web application, Indian companies are well-prepared to provide quality services at competitive prices.

SEO-Optimized Websites for Enhanced Visibility

A great website is of little use if your target audience cannot find it. This is where Search Engine Optimization plays its role. A professional Web Development Company in India, like Takmon Boost, makes sure that your website is designed and developed with the best practices of SEO in mind.

From faster loading speeds and mobile responsiveness to keyword optimization and intuitive navigation, every aspect of your site is optimized to rank higher on search engines. This helps drive organic traffic, increasing your brand's visibility and credibility online.

Mobile-First Approach to Reach More Customers

A mobile-friendly website is no longer an option but a necessity due to the increasing dominance of mobile users. Takmon Boost understands that it is important to go mobile-first and develops responsive websites that offer seamless experiences across devices.

This approach ensures that your website is not only functional but also visually appealing, whether accessed on a desktop, tablet, or smartphone. This way, you can expand your reach and provide a superior user experience to a broader audience by catering to mobile users.

Cutting-Edge Technology for Future-Ready Websites

The digital landscape evolves rapidly, and staying ahead of the curve is vital for maintaining a competitive edge. Partnering with a Web Development Company in India like Takmon Boost gives your brand access to the latest technologies, tools, and frameworks.

From AI-driven features and chatbots to Progressive Web Apps (PWAs) and advanced analytics, Takmon Boost brings together innovative solutions to ensure that your website is future-ready. This not only enhances functionality but also improves customer satisfaction and engagement.
Scalable solutions to support your growth

With growing business, your website should also be evolved to accept increased traffic and more additional features. A professional web development company in India can cater scalable solutions for your requirements.

Takmon Boost creates websites scalable on all aspects so that the idea of scaling up without being disrupted can be brought before you. Be it introducing an entirely new product line or implementing sophisticated functionalities or conducting multinational operations, scalability ensures your web page is robust and runs efficiently.

Full Maintenance and Support

Launching a website is just the beginning. Regular updates, bug fixes, and performance optimizations are crucial for keeping your site functional and secure. Takmon Boost offers comprehensive maintenance and support services, ensuring your website operates at its best at all times.

This proactive approach minimizes downtime, guards against cyber threats, and keeps your site aligned with the latest industry trends, allowing you to have peace of mind and focus on growing your business.

Increasing Your Brand's Credibility and Trust

A website that is well designed projects professionalism and reliability as part of the brand. Takmon Boost guarantees a great-looking website combined with seamless functionality that translates into a positive user experience, leading to trust and credibility on the part of the target audience.

With the help of a Web Development Company in India, you can make an online identity that really strikes a chord with your target audience and enhances your brand's reputation.

Why is Takmon Boost?

Many more options for a Web Development Company in India, still not better at Takmon Boost. Noteworthy for commitment to excellence and innovation in delivering customer-satisfying services, the experienced team of developers and designers here deliver customized solutions tailored especially according to the brand's special need.

Whether you are looking to redesign an existing website or start from scratch, Takmon Boost combines technical expertise with creative vision to help your brand shine online.

Conclusion

In today's competitive digital landscape, it is very important to have a reliable Web Development Company in India that can take your brand to the next level online. Takmon Boost will help you build a strong online presence with customized solutions, cost-effective services, and cutting-edge technology.
`,
      image: sco1,
      alignLeft: false,
    },
    {
      title: "How the Best SEO Company in India Drives Results with Proven Strategies",
      description: "By Takmon BoostThe world of digital is becoming so competitive that standing out on the search engines is not an option, but a necessity. Countless businesses are vying to stay at the top spots of Google and other search engines. The solution here is through search engine optimization. Amongst the thousands of SEO providers, India is now emerging as the center for providing the best possible SEO solutions. Companies like Takmon Boost are on the frontline, with recognition as the best SEO company in India through a proven methodology that works towards tangible outcomes.This blog covers how best SEO practices can change the game of your business and why Takmon Boost is the perfect partner.",
      fullContent: `The Importance of SEO in the Digital Era
SEO is the backbone of any strong online presence. It ensures that your website reaches the right audience, increases organic traffic, boosts brand awareness, and eventually increases revenue. But SEO is not just about keyword inclusion; it is more about the seamless experience for users and aligning content with search engine algorithms.

It's like Takmon Boost – a best SEO company in India – that is technically very strong, comes up with innovative strategies, and really knows market trends and delivers.

What Makes the Best SEO Company in India Tick
The best SEO company in India is always on top of the most talented professionals with deep knowledge about the working and psychology of the search engines and users. Here's what makes Takmon Boost the best SEO company in India:

1. Complete Keyword Research
A complete SEO strategy begins with understanding the right keywords. We research intensively at Takmon Boost to find high-performing keywords relevant to your business. This means your website attracts the right people to stay ahead of your competition.

2. On Page Optimization
The foundation of SEO lies in optimizing your website's content, meta tags, headings, and internal links. We ensure that every aspect of your website aligns with search engine guidelines, making it easier for search engines to crawl and rank your pages effectively.

3. Technical SEO Excellence
Technical SEO is an element that most people miss. We work on your website's loading speed, mobile-friendliness, and security protocols to ensure the best user experience. A technically sound website ranks higher and keeps visitors longer.

4. Engaging and Converting Content
Content is the king of SEO. At Takmon Boost, we create relevant and engaging keyword-based content that reaches out to your target audience. Our blog posts, product descriptions, and other content are crafted with an eye on informing, engaging, and driving conversions.

5. Quality Link Building
Link building remains a significant ranking factor for search engines. Our link-building approaches involve securing high-quality and authoritative backlinks that give credibility to your website and drive up rankings.

6. Data-Driven Strategies
We believe in making informed decisions. Our team uses advanced analytics tools to track performance, measure success, and refine strategies. This data-driven approach ensures consistent improvements and measurable outcomes.

Proven Strategies That Deliver Results
As the best SEO company in India, Takmon Boost follows a structured yet flexible approach to achieve success for our clients. Here's a glimpse into our proven strategies:

1. Local SEO for Targeted Reach
For business entities targeting specific geographies, local SEO is key. We ensure that your digital footprint is optimized to give you prominent ranking in local search so you can connect with the locals most effectively.

2. Voice Search Optimization
With the coming of smart assistants, voice search has already revolutionized the way people have begun seeking information online. In fact, we design strategies so that your SEO encompasses the voice search queries too so that more people may be reached.

3. Mobile-First SEO
As mobile usage continues to rise, ensuring that your website is mobile-friendly will be critical. We implement mobile-first strategies for on-the-go users and enhance your search rankings.

4. AI and Machine Learning Integration
Our team stays at the forefront of things by making use of AI and machine learning tools to predict search trends and adjust our strategies as a result. This kind of futuristic approach ensures your website remains relevant and competitive.

Why Choose Takmon Boost?
At Takmon Boost, we are not just about delivering short-term results; we focus on creating sustainable growth for our clients. Our commitment to excellence, transparency, and innovation has positioned us as the best SEO company in India. Here's why businesses trust us:

Tailored Solutions: We understand that every business is unique, and so are its SEO needs. Our solutions are customized to align with your goals.
Experienced Team: Our team consists of SEO experts who bring years of experience and expertise to the table.
Proven Track Record: From startups to established brands, we have helped businesses across industries achieve remarkable results.
Constant Support: We provide round-the-clock support to address your queries and ensure seamless collaboration.

The Transformative Power of SEO
SEO is not about rankings, but it's more about building a solid foundation for your online success. The right strategies and execution can greatly enhance the visibility, credibility, and profitability of your brand. By joining hands with the best SEO company in India, Takmon Boost, you are able to tap into the true potential of SEO to stand ahead in today's competitive market.

Conclusion
In today's highly competitive digital world, an SEO strategy has become effective. Making the right choice by choosing Takmon Boost is partnering with a team dedicated to driving your business forward through proven strategies and innovative solutions.



`,
      image: sco3,
      alignLeft: true,
    },
  ];

  return (
    <div className="bg-gray-50 text-gray-800 mt-[64px]">
      {/* Header Section */}
      <div
        className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
        style={{
          backgroundImage: `url(${commonbannerimg})`,
        }}
      >
        <h2 className="text-4xl font-bold relative z-10">Our Blogs</h2>
        <p className="text-sm mt-2 relative z-10">Home / Our Blogs</p>
      </div>

      <div className="text-center py-8 bg-white">
        <p className="font-semibold text-sm md:text-base tracking-wide text-orange-600">
          \ Our Blogs \
        </p>
        <h2 className="text-2xl md:text-4xl font-bold mt-2">What We Do</h2>
      </div>

    {/* Blog Section */}
{blogData.map((blog, index) => (
  <section
    key={index}
    className={`flex flex-col lg:flex-row items-center justify-evenly bg-white gap-12 px-4  py-16 ${
      blog.alignLeft ? "" : "lg:flex-row-reverse"
    }`}
  >
    {/* Image Section */}
    <div className="relative flex-shrink-0">
      {/* Decorative Elements */}
      <div className="rounded-md w-24 h-24 absolute -left-12 -top-12 z-0   hidden sm:block md:block">
        <img src={aboutus123} alt="Decoration" className="w-24 h-36" />
      </div>
      <div className="rounded-md w-36 h-36 bg-custom-orenge absolute -left-3 -top-3 z-10   hidden sm:block md:block"></div>
      <div className="relative z-20">
        <img
          src={blog.image}
          alt={blog.title}
          className={`md:w-96 rounded-md w-[17rem] ${
            blog.alignLeft ? "animate-fadeInLeft" : "animate-fadeInRight"
          }`}
        />
      </div>
      <div
        className="rounded-md w-24 h-24 absolute z-0 
        md:left-[21rem] md:top-[5rem] 
        lg:left-[21rem] lg:top-[6rem]
       hidden sm:block md:block"
      >
        <img src={aboutus123} alt="Decoration" />
      </div>
      <div
        className="rounded-md w-36 h-36 bg-custom-gray absolute z-10 
        md:left-[16rem] md:top-[5rem] 
        lg:left-[16rem] lg:top-[5rem] 
         hidden sm:block md:block "
      ></div> {/* This will be hidden on small screens */}
    </div>

    {/* Text Section */}
    <div className="max-w-full md:max-w-md lg:max-w-lg text-center lg:text-left">
      <p className="text-2xl sm:text-3xl lg:text-2xl font-bold text-gray-900">
        {blog.title}
      </p>
      <p className="text-gray-600 mt-4">{blog.description}</p>
      <div className="mt-8 flex flex-wrap gap-6 justify-center lg:justify-start">
        <Button
          type="button"
          text="Read more"
          className="bg-custom-orenge p-3 px-6 rounded-md shadow-md text-white"
          onClick={() => handleClick(blog)}
        />
      </div>
    </div>
  </section>
))}




      {selectedBlog && (
        <div className="p-8 bg-gray-100">
          <h2 className="text-3xl font-bold text-gray-800">{selectedBlog.title}</h2>
          <p className="mt-4 text-gray-700">{selectedBlog.fullContent}</p>
        </div>
      )}
    </div>
  );
};

export default Blogs;


