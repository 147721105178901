import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const CustomWebsite = () => {
    const options = [
        {
          title: 'How Takmon Boost Can Help',
          description:
            "At Takmon Boost, we emphasize your vision, budget, and timeline. Our process includes:Consultation: Understanding of your business needs.Custom Design: Unique website creation.Efficient Development: Availing of latest tools and technologySupport After Launch: Helping ensure you're achieving success for your site We are firm in providing transparent pricing with website development cost in India details on what the costs of development entail so there isn't any surprise. If a startup or a small player on a shoestring budget, or a big company searching for sophisticated functionality, our solutions are suited to meeting both requirements.",
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Why is India an Ideal Location for Website Development?',
          description:'India is one of the global hotspots that provides  inexpensive yet quality web development services. A few reasons why India has gained the most preference include:Competitive pricing: The cost for building a website in India is much lower than most countries.Skilled people: It has a large talent pool of developers and designers.Diverse expertise: Familiarity with multiple platforms, frameworks, and technologiesGet started with Takmon BoostAn investment for any business can be investing in a customized website. For Takmon Boost, commitment to providing first-class solutions catering specifically to needs are well within an affordable website development cost in India.',
          description2 :''
        },
        {
            title: "Online Business? Here's Takmon Boost.",
            description:"Contact Us TODAY to discuss your project and let's get started at creating a website that isn't just up to your expectation but exceeds all of yours.",
            description2 :''
          },
         
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Custom Website "
       subHeading="Custom Web Development in India:"
       description=" A guide for price
In today's digital age, a custom website is more than a virtual presence; it is a vital means for businesses to engage their audience, showcase their products, and establish their brand identity. Therefore, if you plan to build a custom website, knowing the website development cost in India is important. Takmon Boost is all about delivering tailored solutions that can fit your business goals at competitive costs with transparency."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why Choose a Custom Website?"
      description="A custom website offers distinct advantages over generic templates or pre-built designs. It provides:
Unique Design: Tailored to reflect your brand’s personality.
Enhanced Functionality: Features and integrations specifically designed for your needs.
SEO Optimization: Built to rank well on search engines.
Scalability: Adaptable to your business growth.
While the benefits are immense, the website development cost in India varies depending on several factors, which we’ll explore below.
"
      title2="Factors Affecting the Cost of Website Development in India
Type of Website
"
      description2="Simple Websites: Suitable for small businesses and startups, ₹10,000–₹25,000.
E-commerce Websites: Advanced online stores with features, ₹50,000–₹3,00,000.
Enterprise Websites: Complex systems with high functionalities, ₹5,00,000 and above.
Design Requirements
Custom designs, animations, and UI/UX can be expensive but worth the investment.
Features and Functionalities
Integration of payment gateways, CRM tools, and chatbots are added to the overall cost.
Development Team
The expertise of developers, designers, and project website development cost in India  managers determines the costing. At Takmon Boost, we ensure that only experienced professionals work on your project to optimize the results.

Maintenance and Support
The ongoing cost, such as hosting and technical support, is usually unnoticed but necessary for a hassle-free website experience.
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'></h1>
      <p className="text-gray-700 text-sm" >
</p></div>
      
  
     
  </>
  )
}

export default CustomWebsite
