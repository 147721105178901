import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const GoogleAds = () => {
    const options = [
        {
          title: 'Our Google Ads Management Process',
          description:
            'Takmon Boost operates with a time-tested process to guarantee you success from your Google Ads campaigns.This stage involves understanding your business, objectives, and target audience.Setup: Design campaigns by combining good creatives, appropriate strategic bidding, and proper settings in targeting.Keyword strategy: We use advanced tools for finding high-performing keywords, balancing search volume, relevance, and competition.Monitoring the performance of ads through real-time analytics: The critical metrics monitored include click-through rates (CTR), cost-per-click (CPC), and conversion rates.Ongoing Optimization: We optimize your campaigns based on performance data, continuously improving your ROI.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Why Google Ads Is a Must for Online Lead Generation',
          description:'It helps businesses get quality leads and direct access to potential customers. The best targeting features offered are unavailable in any other medium of advertising. Google Ads gives a business the opportunity to communicate with users at different levels of the buying journey. As a leading online lead generation company in Jaipur, Takmon Boost uses Google Ads to create opportunities that bring growth to your business.',
          description2 :''
        },
        {
            title: 'Partner with Takmon Boost Today',
            description:' With the expert management of Google Ads by Takmon Boost, you can fully unlock the power of this advertising platform. Our data-driven approach and commitment to excellence make us the go-to online lead generation company in Jaipur. Whether you want to increase website traffic, boost sales, or expand your reach, we have the expertise to help you achieve your goa',
            description2 :''
          },
         
       
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Google Ads Management "
       subHeading="Google Ads Success Starts with Takmon Boost: Jaipur’s Online Lead Generation Leade"
       description="In today's highly competitive digital marketplace, it has become vital for achieving business growth by having an effective online advertising strategy. Google Ads stands out as one of the most powerful tools for reaching the right audience, increasing visibility, traffic, and conversion rates. Takmon Boost, the best online lead generation company in Jaipur, is specialized in Google Ads management services that deliver measurable results and maximize your ROI."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="What Is Google Ads Management?"
      description="Google Ads management involves the creation, optimization, and monitoring of paid search campaigns to ensure they meet business goals. Through this platform, businesses can display ads to users searching for specific products or services, ensuring that your offerings are seen by the right audience at the right time. Takmon Boost takes the complexity out of managing Google Ads campaigns, allowing you to focus on what you do best: running your business."
      title2="Why Choose Takmon Boost for Google Ads Management?"
      description2="Takmon Boost is a leading online lead generation company in Jaipur, which has understood all the nuances of Google Ads and the need to tailor strategies according to the requirements of the individual business. Here's why our Google Ads management services are different:
      Customized Campaigns: We craft personalized Google Ads campaigns in line with your brand goals and target audience so that every click is valuable.

Keyword Research and Optimization: We carry out extensive keyword research to identify high-converting terms and phrases related to your industry.

Compelling Ad Copy: We write persuasive and engaging ad copy that speaks to your audience and makes them click.

Precise Targeting: From geographic targeting to audience segmentation, we ensure your ads reach the right people, maximizing the efficiency of your budget.

Continuous Monitoring and Optimization: Our team continuously tracks the performance of the campaigns and makes data-driven adjustments to improve the outcomes.

Benefits of Google Ads Management

Investing in professional Google Ads management services can unlock many benefits for your business:

Increased Visibility: Get your business in front of users actively searching for your products or services.

Higher Quality Leads: Attract potential customers who are more likely to convert.

Cost Effectiveness: Our strategic bid management allows you to have the maximum possible return from your advertisement spend.

Faster Deliverables: Unlike organic efforts, Google Ads can immediately get traffic and lead to the website.
Scalable: It doesn't matter if you have a small business or a huge enterprise; our Google Ads campaigns are fully scalable."
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'></h1>
      <p className="text-gray-700 text-sm" >


</p>
</div>
      
  
     
  </>
  )
}

export default GoogleAds
