import React from 'react';
import bannerimg from "../../../assets/BannerImages/bannerimage1.png";

const Professional = () => {
  return (
    <div className="container mx-auto">
    <section className="flex flex-col lg:flex-row lg:flex-nowrap items-center justify-between gap-12 px-0  py-12 flex-wrap">
      {/* Left Image Section */}
      <div className="relative w-full lg:w-[50%]">
        {/* Orange Background Shape */}
        <div className="absolute w-64 h-64 lg:w-80 lg:h-80 rounded-2xl -z-10"></div>

        {/* Main Image */}
        <img
          src={bannerimg} // Replace with your image URL or import
          alt="Team Collaboration"
          className="relative w-[300px] h-[300px] sm:w-[400px] sm:h-[400px] lg:w-[600px] lg:h-[600px] object-cover mx-auto lg:mx-0"
        />
      </div>

      {/* Right Text Section */}
      <div className=" text-center lg:text-left lg:w-[50%] w-full">
        <h3 className="text-orange-500 font-semibold text-lg">
          \Professional Approach\
        </h3>
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900 mt-2">
          Creating Online Trust: A Professional Website's Approach
        </h2>
        <p className="text-gray-600 mt-4">
          A professional website conveys trust in two main ways: it exudes credibility and is friendly and communicative in terms of design. It therefore develops an online presence which appears credible and dependable to any visitor. The web composition should depict a decent picture that says brand values and thus guarantees clients that it will change over guests into long-lasting clients.
        </p>

        {/* Bullet Points */}
        <ul className="mt-6 space-y-4">
          <li className="flex items-center gap-3">
            <div className="w-6 h-6 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold">
              →
            </div>
            <span className="text-lg sm:text-xl font-bold text-gray-900">
              Evolving Customer Expectations
            </span>
          </li>
          <li className="flex items-center gap-3">
            <div className="w-6 h-6 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold">
              →
            </div>
            <span className="text-lg sm:text-xl font-bold text-gray-900">
              Automated And Paperless Seamless
            </span>
          </li>
        </ul>

        {/* Statistics */}
        <div className="mt-8 flex flex-wrap gap-12 justify-center lg:justify-start">
          <div className="text-center">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900">89%</h3>
            <p className="text-gray-600">Client Satisfied</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900">90%</h3>
            <p className="text-gray-600">Finance Consulting</p>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Professional;
