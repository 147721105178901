import React from 'react'
import severs from "../assets/Services/severs.png";

import BannerSection from './SeoServices/BannerSection';

import OurPartner from './OurPartner';


const SocialMManagement = () => {
   

  return (
    <div>
    <BannerSection
        title="Social Media Management"
        description="
Takmon Boost is a leading performance marketing company in Jaipur. We specialize in creating custom social media strategies that not only boost engagement but drive measurable results for your business. We effectively manage your platforms to amplify your brand's reach. Partner with Takmon Boost and transform your social presence into a powerful marketing asset in Jaipur's competitive market."
        mainImage={severs}
        overlayImage={severs}
      />
    
      <OurPartner/>
    </div>
  )
}

export default SocialMManagement
