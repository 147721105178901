import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const InstagramService = () => {
    const options = [
        {
          title: 'How Takmon Boost Elevates Your Instagram Game',
          description:"Takmon Boost offers custom-made Instagram marketing campaigns with measurable results. Here's how we can assist you",
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: '1. Strategic Plan Development',
          description:'We will first of all listen to your business needs and the target audience. Our strategy will be data-driven to help you know best practices, content themes, and techniques for engagement to enhance your reach on Instagram.',
          description2 :''
        },
        {
            title: '2. Content Creation and Management',
            description:"Content is the heart of Instagram marketing. Takmon Boost ensures that every post, Story, and Reel is designed to captivate your audience. Our creative team produces high-quality visuals and compelling captions that align with your brand's voice.",
            description2 :''
          },
          {
            title: '3. Audience Engagement',
            description:'Engaging with your followers is the way to build a loyal community. From responding to comments and messages to hosting interactive polls and live sessions, we help your brand stay connected and relevant.',
            description2 :''
          },
          {
            title: ' 4. Targeted Advertising Campaigns',
            description:'You can reach very specific demographics with Instagram Ads. Takmon Boost is the best digital marketing agency in Gurgaon and boasts of creating and managing successful campaigns for Instagram ad creation with a good return on investment. We are optimizing ad placements, checking performance, and making strategy adjustments to ensure success.',
            description2 :''
          },
          {
            title: '5. Analytics and Reporting',
            description:'We believe in transparency and measurable results. Takmon Boost provides detailed analytics and reports that highlight key metrics, such as engagement rates, follower growth, and campaign performance. This helps you track progress and refine strategies as needed.'
,
            description2 :''
          },
          {
            title: 'Benefits of Partnering with Takmon Boost',
            description:'There are many benefits of choosing Takmon Boost for your Instagram marketing needs:Experience: Our team of professionals caries experience in social media marketing to ensure your campaigns are innovative and effective.Customized solutions: Since we believe every business is different, we work according to the specific needs of yours.Cost-Effective services: As one of the premier digital marketing agency in Gurgaon, we will ensure that premium services offered to you are at very competitive rates to give the best value to your investments.Focus on Results: Our main goal at Takmon Boost is to bring tangible results that will contribute to the growth of your business.',
            description2 :''
          },
          {
            title: 'Why Gurgaon Businesses Need Instagram Marketing',
            description:"For businesses in Gurgaon, a hub of innovation and enterprise, standing out in the digital space is the need of the hour. Instagram is a platform where you digital marketing agency in Gurgaon can express your brand's creativity, connect with local and global audiences, and build a strong digital identity. With Takmon Boost, you gain a trusted partner that understands the Gurgaon market and can help your business thrive on Instagram",
            description2 :''
          },
          {
            title: 'Take Your Instagram Marketing to the Next Level',
            description:"This digital marketing competition requires robustness with an Instagram presence. As the best digital marketing company in Gurgaon, Takmon Boost helps your company reap its success and bring its ideas to life. If it is a startup or if it is an old and established brand, the potential from our team and passion for work can take the business to new heights.",
            description2 :''
          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Instagram Marketing  "
       subHeading="Instagram Marketing: How Takmon Boost Can Boost Your Business."
       description="In today’s fast-paced digital era, businesses are increasingly turning to Instagram to elevate their online presence. Instagram, with its vast user base and visual-centric platform, offers unparalleled opportunities for brands to connect with their audience. If you’re looking for a trusted partner to navigate this dynamic platform, Takmon Boost, a leading digital marketing agency in Gurgaon, is here to transform your Instagram marketing strategy."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why Instagram Marketing Matters"
      description="Instagram is not just a social media platform; it is a powerful tool for businesses to engage with their target audience. With over a billion active users, Instagram offers a unique blend of creative storytelling and direct engagement. Brands can show off their products, build community loyalty, and drive sales by using features such as Stories, Reels, and Shopping."
      title2=""
      description2="However, to completely unlock Instagram's potential, one needs something more than just an account. Businesses require a solid strategy that echoes their message to the audience, reflects the brand's identity, and achieves their objective. This is where Takmon Boost steps in - the best digital marketing agency in Gurgaon."
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'></h1>
      <p className="text-gray-700 text-sm" ></p></div>
      
  
     
  </>
  )
}

export default InstagramService
