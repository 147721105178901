import React from 'react'

import severs from "../assets/Services/severs.png";


import BannerSection from './SeoServices/BannerSection';
import OurPartner from './OurPartner';


const SmoServices = () => {
 

   


  return (
    <div>
    <BannerSection
        title="SMO Services"
        description="
Takmon Boost is a leading digital marketing company in Ajmer that offers high-quality SMO services for your online presence. Using tried and tested strategies, we make the most of social media platforms to increase engagement and brand visibility. Partner with us for innovative solutions that drive growth and make your business stand out in the digital world.


"
        mainImage={severs}
        overlayImage={severs}
      />
    
      <OurPartner/>
    </div>
  )
}

export default SmoServices
