import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet"; // Import Leaflet for custom icon configuration
import "leaflet/dist/leaflet.css";

// Define a custom icon for the location marker
const locationIcon = new L.Icon({
  iconUrl:
    "https://media.istockphoto.com/id/1493681761/vector/orange-colored-map-location-pin.jpg?s=612x612&w=0&k=20&c=pzksh7tO6k6mXns2YUUuDxA6cVFfE1mQPoNHqSHPbfI=", // Custom icon URL
  iconSize: [60, 60], // Size of the icon [width, height]
  iconAnchor: [15, 40], // Anchor point of the icon
  popupAnchor: [0, -40], // Position of the popup relative to the icon
});

const MapPage = () => {
  const jaipurCoordinates = [26.9009927, 75.7141175]; // Jaipur center coordinates

  return (
    <div className="flex justify-center items-center bg-white">
      <div className="relative w-full max-w-5xl p-4 bg-white shadow-lg rounded-md border border-gray-300">
        {/* Map Section */}
        <div className="relative h-[400px] md:h-[600px] rounded-md overflow-hidden shadow-md border border-gray-200">
          <MapContainer
            center={jaipurCoordinates}
            zoom={13}
            scrollWheelZoom={true}
            className="h-full w-full"
          >
            {/* Map tiles from OpenStreetMap */}
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution={`&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">
                 OpenStreetMap</a> contributors`}
            />

            {/* Custom Marker with Location Icon */}
            <Marker position={jaipurCoordinates} icon={locationIcon}>
              <Popup>D78,Raj Laxmi residency, Gandhi Path W, Vaishali Nagar, Jaipur, Rajasthan 302021</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default MapPage;
