import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Takmon Boost full.svg";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import { FaTwitter, FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import CreateAffiliate from "./Affiliate/CreateAffilate";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  // Scroll to top handler
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  return (
    <footer
      className="bg-cover bg-center text-white p-6 md:p-8 flex flex-col justify-center items-center relative"
      style={{
        backgroundImage: `url(${commonbannerimg})`,
      }}
    >
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 pt-8 md:pt-16">
          {/* Logo and Address */}
          <div className="text-center lg:text-left">
            <img
              src={logo}
              alt="Takmon Boost Logo"
              className="w-24 md:w-32 mx-auto lg:mx-0 mb-4"
            />
            <p className="text-sm lg:text-base">
              88/89 Peter Odili Road, Port Harcourt, Rivers State.
            </p>
          </div>

          {/* Navigation Links */}
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 gap-6 md:gap-12 text-center lg:text-left">
            {/* Column 1 */}
            <div className="flex flex-col space-y-2">
              <Link to="/" onClick={handleScrollToTop} className="">
                Home
              </Link>
              <Link to="/services" onClick={handleScrollToTop} className="">
                Services
              </Link>
              <Link to="/about" onClick={handleScrollToTop} className="">
                About
              </Link>
            </div>

            {/* Column 2 */}
            <div className="flex flex-col space-y-2">
              <Link
                to="/affiliate"
                onClick={handleScrollToTop}
                className=" whitespace-nowrap tracking-tighter "
              >
                Affiliate Marketing
              </Link>
              {isOpen && <CreateAffiliate isOpen={isOpen} setIsOpen={setIsOpen} />}
              <Link to="/expertise" onClick={handleScrollToTop} className=" whitespace-nowrap tracking-tighter ">
                Expertise
              </Link>
              <Link to="/blogs" onClick={handleScrollToTop} className="">
                Blog
              </Link>
            </div>

            {/* Column 3 */}
            <div className="flex flex-col space-y-2">
              <Link
                to="/privacyPolicy"
                onClick={handleScrollToTop}
                className=" whitespace-nowrap translate-x-10"
              >
                Privacy Policy
              </Link>
              <Link
                to="/termCondtion"
                onClick={handleScrollToTop}
                className=" whitespace-nowrap translate-x-10"
              >
                Terms & Conditions
              </Link>
              <Link to="/career" onClick={handleScrollToTop} className=" translate-x-10">
                Career
              </Link>
            </div>
          </div>

          {/* Social Media Icons */}
          <div className="text-center">
            <h4 className="font-bold mb-4 translate-x-10">Connect With Us</h4>
            <div className="flex justify-center space-x-4">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition translate-x-10"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition translate-x-10"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition translate-x-10"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition translate-x-10"
              >
                <FaFacebook size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-center text-xs md:text-sm">
          <p>@do-not Copyright. All rights reserved. Designed by Takmon Boost</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


