import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import patners from "../assets/Services/patners.jpg"
const OurPartner = () => {
    const partners = [
        {
            name: 'Amit',
            role: 'Head Manager',
            img: patners, // Replace with actual image URL
            socialLinks: [
                { icon: <FaFacebookF className=" size-9 p-2 bg-orange-500 text-white  rounded-md" />, link: '#' },
                { icon: <FaInstagram className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaTwitter className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaLinkedinIn className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
            ],
        },
        {
            name: 'Amit',
            role: 'Head Manager',
            img: patners, // Replace with actual image URL
            socialLinks: [
                { icon: <FaFacebookF className=" size-9 p-2 bg-orange-500 text-white  rounded-md" />, link: '#' },
                { icon: <FaInstagram className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaTwitter className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaLinkedinIn className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
            ],
        },
        {
            name: 'Amit',
            role: 'Head Manager',
            img: patners, // Replace with actual image URL
            socialLinks: [
                { icon: <FaFacebookF className=" size-9 p-2 bg-orange-500 text-white  rounded-md" />, link: '#' },
                { icon: <FaInstagram className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaTwitter className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaLinkedinIn className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
            ],
        },
        {
            name: 'Amit',
            role: 'Head Manager',
            img: patners, // Replace with actual image URL
            socialLinks: [
                { icon: <FaFacebookF className=" size-9 p-2 bg-orange-500 text-white  rounded-md" />, link: '#' },
                { icon: <FaInstagram className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaTwitter className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
                { icon: <FaLinkedinIn className="size-9 p-2 bg-orange-500 text-white  rounded-sm" />, link: '#' },
            ],
        },
        // Add more partners as needed
    ];

    return (
        <div className="bg-gray-100 font-sans py-10">
            <div className="text-center">
                <p className="text-orange-500 text-lg font-medium">\ Grow Your Business \</p>
                <h2 className="text-gray-800 text-2xl font-bold mt-2">Our Partner</h2>
            </div>

            <div className="max-w-4xl mx-auto mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                {partners.map((partner, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center"
                    >
                        {/* Partner Image */}
                        <div className="w-20 h-20 rounded-full overflow-hidden mb-4">
                            <img
                                src={partner.img}
                                alt={partner.name}
                                className="w-full h-full object-cover"
                            />
                        </div>
                        {/* Partner Name and Role */}
                        <div className="text-lg font-semibold text-gray-800">{partner.name}</div>
                        <div className="text-sm text-gray-500">{partner.role}</div>
                        {/* Social Links */}
                        <div className="flex items-center justify-center space-x-3 mt-3  ">
                            {partner.socialLinks.map((social, i) => (
                                <a
                                    key={i}
                                    href={social.link}
                                    className="hover:text-orange-600"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {social.icon}
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurPartner;
