// 1. Import statements should always be at the top
import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { SlSocialLinkedin } from "react-icons/sl";
import { IoLogoInstagram } from "react-icons/io";
import { LuFacebook } from "react-icons/lu";
import { RxTwitterLogo } from "react-icons/rx";
import MapPage from "./MapPage";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    toast.success("Form submitted successfully!", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    reset();
  };

  return (
    <>
      <div className="bg-gray-50 text-gray-800 mt-[64px]">
        {/* Banner Section */}
        <div
          className="bg-cover bg-center text-white  lg:h-56 md:h-56 p-8 flex flex-col justify-center items-center relative"
          style={{ backgroundImage: `url(${commonbannerimg})` }}
        >
          <h1 className="text-4xl font-bold relative z-10">Contact Us</h1>
          <p className="text-sm mt-2 relative z-10">Home / Contact Us</p>
        </div>

        {/* Get In Touch Section */}
        <div className="text-center py-8 bg-white">
          <p className="font-semibold text-sm md:text-base tracking-wide text-orange-600">
            \ Get In Touch \
          </p>
          <h2 className="text-2xl md:text-4xl font-bold mt-2">
            Hey! Let’s Talk
          </h2>
        </div>

        {/* Content Section */}
        <div className="container mx-auto  flex flex-col md:flex-row justify-between items-center bg-white px-3 md:px-14 py-8">
          {/* Form Section */}
          <div className="w-full md:w-2/3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                placeholder="Name"
                className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name.message}</p>
              )}

              <input
                type="email"
                placeholder="Email"
                className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                })}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}

              <input
                type="tel"
                placeholder="Phone"
                className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                })}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm">{errors.phone.message}</p>
              )}

              <textarea
                placeholder="Your Message"
                className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 resize-none rounded-lg"
                rows="4"
                {...register("message", { required: "Message is required" })}
              />
              {errors.message && (
                <p className="text-red-500 text-sm">{errors.message.message}</p>
              )}

              <button
                type="submit"
                className="w-full md:w-32 bg-custom-orengelight text-white font-bold py-4 rounded-lg hover:bg-orange-600 transition duration-300"
              >
                Send Now
              </button>
            </form>
            <ToastContainer />
          </div>

          {/* Contact Info Section */}
          <div className="w-full md:w-1/2 flex flex-col bg-white text-gray-800 p-6 sm:p-10 lg:p-16 space-y-8">
            {/* Call Section */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-50 rounded-md shadow-lg">
                <BiPhoneCall className="text-2xl text-orange-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-orange-600">
                  Call Anytime
                </h3>
                <p className="text-sm">+91 6376925384</p>
                <p className="text-sm">+91 9352381775</p>
              </div>
            </div>

            {/* Email Section */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-50 rounded-md shadow-lg">
                <MdOutlineMail className="text-2xl text-orange-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-orange-600">
                  Send Email
                </h3>
                <p className="text-sm">support@tackmoonboost.com</p>
                <p className="text-sm">hello@tackmoonboost.com</p>
              </div>
            </div>

            {/* Address Section */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-50 rounded-md shadow-lg">
                <FaLocationDot className="text-2xl text-orange-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-orange-600">
                  Visit Us
                </h3>
                <p className="text-sm">
                  D78, Raj Laxmi residency, Gandhi Path W, Vaishali Nagar,
                  Jaipur, Rajasthan
                </p>
              </div>
            </div>

            {/* Social Media Section */}
            <div className="w-full flex flex-col space-y-4">
              <h3 className="text-xl font-bold text-gray-800">Follow us</h3>
              <div className="flex space-x-4">
                <a
                  href="#"
                  className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
                >
                  <SlSocialLinkedin className="text-2xl" />
                </a>
                <a
                  href="#"
                  className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
                >
                  <IoLogoInstagram className="text-2xl" />
                </a>
                <a
                  href="#"
                  className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
                >
                  <LuFacebook className="text-2xl" />
                </a>
                <a
                  href="#"
                  className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
                >
                  <RxTwitterLogo className="text-2xl" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <MapPage />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
