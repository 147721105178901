import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const OffPageSeo = () => {
    const options = [
        {
          title: 'Key Off-Page SEO Techniques That Work',
          description:
            ' Link BuildingOff-page SEO has link building as one of its primary components. Quality backlinks from authoritative websites in your niche not only drive traffic but also enhance the domain authority of your website. As a professional SEO company in India, Takmon Boost uses white-hat techniques to build quality backlinks that help your website climb up the search engine rankings.',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Social Media Engagement',
          description:'Social media is very important for off-page SEO. Sharing some valuable content in Facebook, Twitter, Instagram, and LinkedIn creates visibility about the brand, drives traffic, and increases the number of users that engage. As you hire a professional SEO Company in India, that is, Takmon Boost, makes sure that the strategy of using social media should be aligned to your business needs, thus letting you form an online identity.',
          description2 :''
        },
        {
            title: 'Content marketing and Guest posting:',
            description:'Content marketing is one of the most powerful off-page SEO strategies. When you create informative, engaging, and valuable content on other authoritative websites, you not only reach a broader audience but also get backlinks to your website. Takmon Boost specializes in creating and promoting high-quality guest posts that link back to your site, enhancing your visibility and SEO ranking.',
            description2 :'not only reach a broader audience but also get backlinks to your website. Takmon Boost specializes in creating and promoting high-quality guest posts that link back to your site, enhancing your visibility and SEO ranking.Brand mentions on trustworthy sites, even if you have no link to it, will also affect your credibility on your website. Good online reviews on review sites increase your SEO effort since it boosts the level of trust and authority. Takmon Boost emphasizes a good brand image on the web; hence, it gives you a reputation that search engines can rely on.'
          },
          {
            title: 'Why Take Our Expert SEO Company in India for Your Business',
            description:'Takmon Boost is an Indian professional SEO company which has helped many companies belonging to different industries get themselves and enhance their online presence and even ranks. Since this is an off-page SEO techniques specialist SEO company, Takmon Boost will ensure that the proper attention is given to your website.',
            description2 :''
          },
          
      ];
  return (
    <>
    <ScoCommom
     
       heading="Off-Page SEO 
"
       subHeading="Off-Page SEO: Why Choosing a Professional SEO Company in India Like Takmon Boost Is Important."
       description="Presently, in the online world, a good online presence becomes significantly important for any successful business. While on-page SEO plays a crucial role to enhance your website's ranking, the off-page SEO also is performing a very important role that creates traffic, builds authority and improves the credibility of a website. So, if you are looking for a reliable and experienced professional SEO company in India, Takmon Boost is your answer to getting great results.
"
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Understanding Off-Page SEO"
      description="
Off-page SEO is the activities that have to be done outside your website to enhance its search ranking. Unlike on-page SEO, it is concerned with optimizing contents, meta tags, and site structure. Off-page SEO, however, concentrates on building your website's reputation, authority, and trustworthiness on the web. Techniques involved include link building, social media engagement, content marketing, and more.
"
      title2=""
      description2="By utilizing off-page Web optimization, you're basically indicating to web crawlers that your site is valid, significant, and meriting higher rankings. Furthermore, with regards to executing off-page Web optimization techniques, it's pivotal to collaborate with an expert Website design enhancement organization in India, for example, Takmon Lift, that comprehends the subtleties of the constantly changing calculations of web search tools like Google."
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >Off-page Website optimization is one of the main parts of a fruitful computerized promoting methodology. At the professional SEO company in India  point when you employ an expert Search engine optimization organization in India like Takmon Lift, you can be guaranteed that your site benefits from the best off-page Website design enhancement methods, including third party referencing, online entertainment commitment, and content promoting.

So, if you also want to take your business towards the next level, look no further than Takmon Boost- A professional SEO company in India -where you can get help to dominate the digital earth.



</p></div>
      
  
     
  </>
  )
}

export default OffPageSeo
