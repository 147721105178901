import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const FacebookMarketing = () => {
    const options = [
        {
          title: 'Why is Takmon Boost?',
          description:
            " Takmon Boost is a leading performance marketing company in Jaipur, specialized in creating data-driven Facebook marketing strategies designed to deliver tangible outcomes. Here's what sets us apart ",
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: '',
          description:'1. Customized CampaignsWe understand that no two businesses are identical. Our team takes its time to learn about your goals, audience, and industry to create targeted marketing campaigns that resonate well with your target customers.',
          description2 :''
        },
        {
            title: '',
            description:'2. Expert TeamOur experienced marketing professionals have spent years managing Facebook advertising campaigns across a variety of different industries. We are currently on the latest trends and changes to the platform to help keep your business up with the curve.',
            description2 :''
          },
          {
            title: '',
            description:'3. Data-Driven ApproachAt Takmon Boost, we tap into the power of analytics to ensure that your marketing effort is optimized for maximum ROI. From audience segmentation to tracking ad performance, we will make every decision based on data',
            description2 :''
          },
          {
            title: ' ',
            description:'4. Local ExpertiseSince we are a Jaipur-based company, we have first-hand knowledge of how this local market works. With such insight, we can deliver the right performance marketing company in Jaipur  kind of campaign for your business to give your business that edge it needs.',
            description2 :''
          },
          {
            title: ' How Takmon Boost Drives Success with Facebook Marketing',
            description:'1. Audience ResearchWe start by conducting in-depth research to identify your ideal audience. This ensures that your ads reach people who are most likely to convert.',
            description2 :'2. Creative DevelopmentOur team designs visually appealing and engaging ad creatives that capture attention and inspire action.',
       
          },
          {
            title: ' How Takmon Boost Drives Success with Facebook Marketing',
     
          
           
            description2 :'3. Strategic Campaign ManagementWe handle every aspect of your campaign, from setup to optimization, ensuring it performs at its best throughout its lifecycle.'},
            {
            title: 'Success Stories',
            description :'Takmon Boost has given numerous Jaipur businesses their best chance to make remarkable success out of their Facebook marketing. From branding to increasing traffic on a website and ultimately the sales of the products, our customers always enjoy the advantages of working with a trustworthy performance marketing company in Jaipur.',
       
            },
            {
                title: 'Start Working with Takmon Boost',
                description :'Takmon Boost-Get your business on Facebook. We, the most reputed performance marketing agency in Jaipur, shall get campaigns going on with successful execution to create leads for our clients. From a small business that aims to reach through maximum possible advertising on the online interface to large enterprise business in their quest to enhance the rates of conversion, we do the trick.',
           
                }
              
          
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Facebook Marketing "
       subHeading="Facebook Marketing: Unlock Your Business Potential with Takmon Boost."
       description="In this digital-first world, Facebook marketing is the foundation of any business looking to maximize its online presence and engage with the target audience more effectively. As a leader in performance marketing companies in Jaipur, Takmon Boost performance marketing company in Jaipur  offers the most advanced strategies to maximize your business growth through Facebook's unparalleled advertising platform."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why Facebook Marketing Matters"
      description="With more than 2.9 billion monthly active users, the platform gives businesses the capacity to reach audiences worldwide- an incredible opportunity for different audiences across the globe. The sophisticated algorithms and the data-driven tools used for advertising allow your message to appear in front of the target audience at the right moment. For businesses in Jaipur and other cities, therefore, tapping into Facebook's prowess with the expertise of a performance marketing company in Jaipur like Takmon Boost can be the ticket to measuring success."
      title2="Key Benefits of Facebook Marketing"
      description2="Massive Reach
Facebook's vast global user base means your brand can reach out to audiences around the world, creating a new opportunity for growth.

Accurate Targeting
Advanced targeting capabilities enable you to target campaigns according to demographics, interests, behavior, and location—all very ideal for local businesses in Jaipur.

Cost-Effective Advertising
Flexible budget options from Facebook make it affordable for businesses of all sizes, and you can make the most of your marketing spend.

Actionable Insights
You will get a full-fledged analytics tool to track your campaign and improve your strategies to deliver better results.
"
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'></h1>
      <p className="text-gray-700 text-sm" ></p></div>
      
  
     
  </>
  )
}

export default FacebookMarketing
