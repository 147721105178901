import React from 'react'
import SeoCard from '../NestedCommon/ServicesNestedDropdown/SeoCard';
import IntroSection from '../NestedCommon/ServicesNestedDropdown/IntroSection';
import ScoCommom from '../NestedCommon/ScoCommom';
import patners from '../../assets/Services/severs.png'
const OutsourceSeo = () => {
    const options = [
        {
          title: 'FIRST OUTERSCO',
          description:
            ' Expertise and Experience Takmon Boost is the number one SEO company in Jaipur with years of expertise and experience. They know all the subtleties that go into SEO and are conscious of the latest trends, tools, and strategies in the field. The team is proven to help businesses rank better on search engines and realize long-term success.This demands far more than just knowledge; rather, it asks for strategic thinking with constant refinement. Managing your SEO with the help of Takmon Boost means ensuring that the brightest minds are put on doing so in such a way to craft your approach precisely as per the business vision',
          bgColor: 'bg-orange-500 text-white',
        },
        {
          title: 'Saves time and effort',
          description:'This could comprise of keyword research, content development, link building, technical SEO, and tracking of the performance. It is quite chaotic and requires full-time attention with constant effort. This you can outsource to the best SEO company in Jaipur; hence you save your time and all other resources to utilize it somewhere else. This is the manner in which you advance to lead beyond the opposition while the experts are on top to deal with your Search engine optimization.',
          description2 :''
        },
        {
            title: 'Practical',
            description:'Setting up an in-house Search engine optimization group requires a ton of interest in employing, preparing, and keeping up with the staff. You also have to invest in different SEO tools and technologies. The best SEO company in Jaipur like Takmon Boost can present a cost-effective alternative solution. Outsourcing can get you the best SEO services for only a fraction of the overall cost, maximizing value from an investmen',
            description2 :''
          },
          {
            title: 'Customized SEO Strategies',
            description:'Each business is distinct, and thus are their SEO requirements. Best SEO service in Jaipur, Takmon Boost realizes this very fact and therefore devises its SEO strategies with customization aligned with your specific business requirements. Be it small local enterprises or big firms; they construct the strategies specifically suited for you, for guaranteed outcomes.',
            description2 :''
          },
          {
            title: ' Latest Available SEO Tools End',
            description:'SEO encompasses a wide range of tools as far as keyword analysis, website performance is concerned, and assessment of the strategy. Takmon Boost possesses advanced SEO tools for analyzing deeply to refine the strategy and provide more effective results. A very expensive cost to onboard an in-house team will invest in such tools; outsourcing to the best SEO company in Jaipur assures cutting-edge technology at reasonable prices..',
            description2 :''
          },
     
      ];
  return (
    <>
    <ScoCommom
     
       heading="Outsource SEO "
       subHeading="Re-appropriate Website design enhancement to the Best Web optimization Organization in Jaipur: Lift Your Business with Takmon Lift"
       description="Takmon Lift is the best Website design enhancement organization in Jaipur, offering master re-appropriating Web optimization best seo company in Jaipur  administrations to help your web-based presence. We work in driving natural rush hour gridlock, improving rankings, and guaranteeing business development with custom-made Web optimization techniques."
      //  primaryButtonText="Request Proposal"
      //  primaryButtonLink="#request-proposal"
       secondaryButtonText="SEO Packages"
       secondaryButtonLink="/ourPlan"
       imageSrc={patners}
       imageAlt="Pest Control SEO Services"/>
         
      
   
      <IntroSection
      // breadcrumb="Home / Services"
      title="Why SEO to Takmon Boost?"
      description="SEO is a  very complex and time-taking activity, which involves non-stop updates and strategy changes, along with all the knowledge of search algorithms. Hence, instead of doing everything in-house, outsourcing a business's SEO needs to an expert SEO firm like Takmon Boost permits businesses to focus on other core operations while letting their experts handle their online marketing requirement. Here's why your business needs to outsource to the best SEO company Jaipur has to offer."
      title2=""
      description2=""
    />
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-10">
      {options.map((option, index) => (
        <SeoCard
          key={index}
          title={option.title}
          description={option.description}
          description2={option.description2}

          bgColor={option.bgColor}
        />
      ))}
     
    </div>
    <div className='px-20 py-10 items-center justify-between '>
      <h1 className='font-bold text-lg mb-2'>Conclusion</h1>
      <p className="text-gray-700 text-sm" >Outsource SEO to a trustworthy SEO company such as Takmon Boost to dramatically boost your business's online presence and save time, money, and effort. Partnering with the best SEO company in Jaipur gets you top-level expertise, customized strategies, and support on an ongoing basis, and your website will rank and maintain high rankings. If you are a business looking to take your online presence to that next level, then Takmon Boost is probably your go-to.


</p></div>
      
  
     
  </>
  )
}

export default OutsourceSeo
