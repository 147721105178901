// import React from 'react'

// const BannerSection = ({ title, description, mainImage, overlayImage }) => {
//   return (
//     <>
    
//    <section className="relative bg-cover bg-center pt-0 bg-custom-orenge bg-gradient-to-l  py-10">
//           {/* Section Container */}
//           <div className="grid grid-cols-12 gap-4 items-center w-full px-6 lg:px-16   text-white py-10  mt-[64px] pt-28">
//             {/* Left Side */}
//             <div className="col-span-12 lg:col-span-7 lg:pl-6 lg:ml-4 rounded-lg lg:bg-transparent text-center lg:text-left w-full md:-mt-11">
//             <h2 className="text-2xl lg:text-4xl font-bold lg:ml-20 mb-2">{title}</h2>
//             <p className="text-sm lg:text-lg leading-relaxed lg:px-20">{description}</p>
//             </div>
  
//             {/* Right Side */}
//             <div className="col-span-12 lg:col-span-4 relative text-center mt-6 lg:mt-0 lg:ml-28 md:m-auto sm:relative">
//             <div className="relative z-0 flex flex-col items-center md:items-start md:space-y-4 md:relative sm:relative">
//                 {/* Main Image */}
//                 <img
//             src={mainImage}
//             alt="Main Visual"
//    className="rounded-lg w-3/4 sm:relative md:w-72 md:m-auto lg:w-full h-auto relative mx-auto md:mx-0 md:relative "
//           />
//                 {/* Overlay Image - Hidden on small screens */}
//                 <img
//             src={overlayImage}
//             alt="Overlay Visual"
//             className="rounded-lg w-20 md:w-32 lg:w-48 h-auto relative md:mt-4 md:ml-4 lg:absolute lg:-left-36 md:-left-32 md:absolute sm:absolute md:top-24 hidden sm:block"
//           />
//               </div>
//             </div>
//           </div>
//         </section>
//     </>
//   )
// }

// export default BannerSection


import React from 'react';

const BannerSection = ({ title, description, mainImage, overlayImage }) => {
  return (
    <section className="relative bg-cover bg-center pt-0 bg-custom-orenge bg-gradient-to-l py-10">
      {/* Section Container */}
      <div className="grid grid-cols-12 gap-4 items-center w-full px-6 lg:px-16 text-white py-10 mt-[64px] pt-28">
        {/* Left Side */}
        <div className="col-span-12 lg:col-span-7 lg:pl-6 lg:ml-4 rounded-lg lg:bg-transparent text-center lg:text-left w-full md:-mt-11">
          <h2 className="text-2xl lg:text-4xl font-bold lg:ml-20 mb-2">{title}</h2>
          <p className="text-sm lg:text-lg leading-relaxed lg:px-20">{description}</p>
        </div>

        {/* Right Side */}
        <div className="col-span-12 lg:col-span-5 relative text-center mt-6 lg:mt-0 lg:ml-28 md:m-auto sm:relative">
          <div className="relative z-0 flex flex-col items-center md:items-start md:space-y-4 md:relative sm:relative">
            {/* Main Image */}
            <img
              src={mainImage}
              alt="Main Visual"
              className="rounded-lg w-3/4 sm:relative md:w-72 md:m-auto lg:w-96 h-auto relative mx-auto md:mx-0 md:relative"
            />
            {/* Overlay Image - Hidden on small screens */}
            <img
              src={overlayImage}
              alt="Overlay Visual"
              className="rounded-lg w-20 md:w-32 lg:w-60 h-auto relative md:mt-4 md:ml-4 lg:absolute lg:-left-36 md:-left-32 md:absolute sm:absolute md:top-24 lg:top-32 hidden sm:block"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
