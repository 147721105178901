import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";

// PlanCard Component (Reusable)
const PlanCard = ({ title, price, description }) => {
  return (
    <div className="border border-orange-300 rounded-lg p-6 shadow-md text-center">
      <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
      <p className="text-4xl font-semibold text-orange-500 mb-4">{price}</p>
      <p className="text-gray-600 text-sm">{description}</p>
    </div>
  );
};

// Packages Component
const Packages = () => {
  const packageData = [
    {
      category: "Our Packages (32,000)",
      plans: [
        {
          title: "Ecommerce Website",
          price: "₹24,000",
          description:
            "Build a powerful online store with secure payment gateways and user-friendly navigation.",
        },
        {
          title: "ON-Page Seo",
          price: "₹110/Page",
          description:
            "Takmon Boost involves an improvement in the relevance of your website to local search queries by implementing on-page SEO techniques.",
        },
        {
          title: "Meta Ad Campaign",
          price: "₹7,999",
          description:
            "The meta description is an abridged version of the content of your page and will appear in the SERPs.",
        },
      ],
    },
    {
      category: "Our Packages 2 (35,000) ",
      plans: [
        {
          title: "Service-Website",
          price: "₹15,000",
          description:
            "We create unique, responsive, and visually appealing websites tailored to your brand",
        },
        {
          title: "On-Off Page SEO",
          price: "₹9,999",
          description:
            "On-Off Page SEO is the process of optimizing individual web pages to rank higher and earn more relevant traffic in search engines.",
        },
        {
          title: "Google My Business",
          price: "₹10,999",
          description:
            "This aids the development of a focused and successful SEO strategy by providing premium-quality backlinks.",
        },
      ],
    },
    {
      category: "Our Packages 3(29,000)",
      plans: [
        {
          title: "Meta Ad Campaign",
          price: "₹7,999",
          description:
            "The meta description is an abridged version of the content of your page and will appear in the SERPs.",
        },
        {
          title: "Social Media Marketing",
          price: "₹10,999",
          description:
            "Social media is very important for off-page SEO. Share valuable content on Facebook, Twitter, Instagram, and LinkedIn.",
        },
        {
          title: "Landing Website",
          price: "₹9999",
          description:
            "We create unique, responsive, and visually appealing websites tailored to your brand",
        },
      ],
    },
  ];

  return (
    <div className="text-gray-800 mt-16">
      {/* Header Section */}
      <div
        className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center"
        style={{ backgroundImage: `url(${commonbannerimg})` }}
      >
        <h2 className="text-4xl font-bold">Packages</h2>
        <p className="text-sm mt-2">Home / Packages</p>
      </div>

      {/* Packages Section */}
      <div className="max-w-7xl mx-auto px-8 md:px-8 py-12">
        {packageData.map((section, index) => (
          <div key={index} className="mb-12">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              {section.category}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-9">
              {section.plans.map((plan, idx) => (
                <PlanCard
                  key={idx}
                  title={plan.title}
                  price={plan.price}
                  description={plan.description}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Packages;
